import React, { useState, useEffect } from "react";
import "./Security.css";
import UpdatePassWord from "./UpdatePassWord/UpdatePassWord";

const Security = () => {
  return (
    <div className="ContenerSecurity">
      <UpdatePassWord />
    </div>
  );
};

export default Security;
