import React, { useState, useEffect } from "react";
import "./ManageSocialEventBooking.css";
import DisplayAndValidateParticipant from "./DisplayAndValidateParticipant/DisplayAndValidateParticipant";
import DisplaySocialEventForFackOffice from "./DisplaySocialEventForFackOffice/DisplaySocialEventForFackOffice";
import { DataAnalyseUseOfThePlatform } from "../../../../Logic/HandleClickFunctions";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

const ManageSocialEventBooking = () => {
  const [SelectedEvent, SetSelectedEvent] = useState(null);
  const [SelectedParticipant, SetSelectedParticipant] = useState(null);

  const [Data, setData] = useState(null);

  const [Akasha] = useCollection(collection(getFirestore(app), "Akasha"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      let Data = await DataAnalyseUseOfThePlatform();
      setData(Data);
    }
    fetchOrders();
  }, [Akasha]);

  console.log(SelectedEvent);
  return (
    <div className="ContenerManageSocialEventBooking">
      {Data && (
        <>
          <DisplaySocialEventForFackOffice
            SetSelectedEvent={SetSelectedEvent}
            SelectedEvent={SelectedEvent}
            Data={Data}
          />
          {SelectedEvent && (
            <DisplayAndValidateParticipant SelectedEventID={SelectedEvent.ID} />
          )}
        </>
      )}
    </div>
  );
};

export default ManageSocialEventBooking;
