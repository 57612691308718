import React, { useState, useEffect } from "react";
import { getBookingForTheWeek } from "../../Logic/HandleClickFunctions";

const DisplaySanctuaryBookingAdmin = () => {
  const [BookingData, setBookingData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const Booking = await getBookingForTheWeek();
      setBookingData(Booking);
    }
    fetchData();
  }, []);
  console.log(BookingData);
  return (
    <>
      {" "}
      <div></div>
      <div>
        {BookingData &&
          BookingData.map((booking) => {
            let BokkingDate = new Date(booking.BookingDate.seconds * 1000);
            return <div>{BokkingDate.toDateString()}</div>;
          })}
      </div>
      <div></div>
    </>
  );
};

export default DisplaySanctuaryBookingAdmin;
