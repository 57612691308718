import React, { useState, useEffect } from "react";
import "./ModalBookAClass.css";
import ModalBookAClassMobileMode from "./ModalBookAClassMobileMode/ModalBookAClassMobileMode";
import ModalBookAClassComputerMode from "./ModalBookAClassComputerMode/ModalBookAClassComputerMode";

const ModalBookAClass = ({ CalssData, UserID }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Fonction pour mettre à jour la largeur de la fenêtre
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Ajouter l'écouteur d'événements
    window.addEventListener("resize", handleResize);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {windowWidth >= 1100 ? (
        <ModalBookAClassComputerMode CalssData={CalssData} UserID={UserID} />
      ) : (
        <ModalBookAClassMobileMode CalssData={CalssData} UserID={UserID} />
      )}
    </>
  );
};

export default ModalBookAClass;
