import "./MenuQuestion2options.css";
import React, { useState } from "react";
import { AddNewAkashaPAss } from "../../Logic/HandleClickFunctions";

function MenuQuestion2options({ menuChoice, setmenuChoice }) {
  return (
    <>
      <div></div>
      <div className="contenerQuestion">
        <div className="TextContener">
          <div className="TextWelcome">Welcome to Sanctuary App</div>
          <div className="TextWhatDo">What do you want to do ?</div>
        </div>
        <div className="ChoiceContener">
          <div className="ContenerBoxchoice shadow1">
            <div
              style={{
                marginBottom: "25px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              onClick={() => setmenuChoice(1)}
            >
              Book a Table at the Sanctuary
            </div>
            <img
              src="/images/champagne-glass.png"
              alt="Champagne Glass"
              style={{ width: "100px", height: "100px", marginBottom: "45px" }}
            />
            <div
              className="ButtonLetSBook ButtonLetSBookColor1"
              onClick={() => setmenuChoice(1)}
            >
              Let's book !
            </div>
          </div>
          <div className="ContenerBoxchoice shadow2">
            <div
              style={{
                marginBottom: "25px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Book a Wellness Class
            </div>
            <img
              src="/images/lotus.png"
              alt="Champagne Glass"
              style={{ width: "100px", height: "100px", marginBottom: "45px" }}
            />
            <div
              className="ButtonLetSBook ButtonLetSBookColor2"
              onClick={() => setmenuChoice(2)}
            >
              Let's book !
            </div>
          </div>
          <div className="ContenerBoxchoice shadow3">
            <div
              style={{
                marginBottom: "25px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Book a Social Event
            </div>
            <img
              src="/images/sanctuary.png"
              alt="Champagne Glass"
              style={{ width: "100px", height: "100px", marginBottom: "45px" }}
            />
            <div
              className="ButtonLetSBook ButtonLetSBookColor3"
              onClick={() => setmenuChoice(3)}
            >
              Let's book !
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
}

export default MenuQuestion2options;
