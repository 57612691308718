import "./FirstConnectionProcess.css";
import React, { useState, useEffect } from "react";
import {
  CheckIfValidAkashaPass,
  HandleCreateAcountWithAkashaPass,
  HandleUpdateUserData,
} from "../../Logic/HandleClickFunctions";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig";

function FirstConnectionProcess({ setNoLogInParcout }) {
  const [akashaPass, setAkashaPass] = useState("");
  const [isValidAkashaPAss, setisValidAkashaPAss] = useState(true);
  const [step, setStep] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [SelectedMembership, setSelectedMembership] = useState("");
  const [AcceptCGU, setAcceptCGU] = useState(false);
  const [isValidPAssWord, setisValidPAssWord] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  console.log(isValidPAssWord);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(2);
    // Logique pour soumettre le formulaire ou créer un compte
    console.log(userInfo);
  };

  const handleSubmitFormPass = async (e) => {
    e.preventDefault();

    let IsFormError = false;
    if (userInfo.PassWord !== userInfo.ConfirmPassWord) {
      setisValidPAssWord("Passwords do not match.");
      setStep(2);
      IsFormError = true;
    }

    if (userInfo.PassWord.length < 7) {
      setisValidPAssWord("Your Password should be at least 7 characters.");
      setStep(2);
      IsFormError = true;
    }
    if (IsFormError === false) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      const handleCreateNewUser = async (e) => {
        setError("");
        setSuccess("");

        const { Email, PassWord } = userInfo;
        console.log(Email);
        console.log(PassWord);
        try {
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            Email,
            PassWord
          );
          await delay(5000);
          const userId = userCredential.user.uid;
          console.log(userCredential);
          console.log(userId);
          console.log(userInfo);
          await HandleUpdateUserData(userId, userInfo);
          await HandleCreateAcountWithAkashaPass(akashaPass);
          setSuccess("User created successfully");
        } catch (error) {
          setError(error.message);
        }
      };
      await handleCreateNewUser();
    }
  };

  const handleCheckIfValidAkashaPass = async () => {
    const IsValid = await CheckIfValidAkashaPass(akashaPass);
    console.log(IsValid);
    if (IsValid) {
      setisValidAkashaPAss(true);
      setStep(1);
    } else {
      setisValidAkashaPAss(false);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleCreateNewUser = async (e) => {
    setError("");
    setSuccess("");

    const { Email, PassWord } = userInfo;
    console.log(Email);
    console.log(PassWord);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        Email,
        PassWord
      );
      await delay(5000);
      const userId = userCredential.user.uid;
      console.log(userCredential);
      console.log(userId);
      console.log(userInfo);
      await HandleUpdateUserData(userId, userInfo);
      await HandleCreateAcountWithAkashaPass(akashaPass);
      setSuccess("User created successfully");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="ContenerFirstConnectionProcess">
      {step == 0 && (
        <div>
          <div className="EntertoTheSanctuaryText">
            Sign Up On Sanctuary App
          </div>
          <div style={{ marginLeft: "30px" }}>
            <div className="PassText">Enter Your Akasha Pass</div>
            <input
              type="akashaPass"
              name="akashaPass"
              placeholder="Akasha Pass"
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                width: "92%",
              }}
              onChange={(e) => setAkashaPass(e.target.value)}
            />
            {isValidAkashaPAss === false && (
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "10px",
                }}
              >
                Akasha Pass not valid
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "15px",
                justifyContent: "center",
              }}
            >
              <div
                className="ButtonBack-FirstConnectionProcess"
                onClick={() => setNoLogInParcout(0)}
              >
                Back
              </div>
              {akashaPass.length > 5 ? (
                <div
                  className="ConfirmButtonNormalMode"
                  onClick={handleCheckIfValidAkashaPass}
                >
                  {" "}
                  Confirm
                </div>
              ) : (
                <div className="ConfirmButtonDenieMode">Confirm </div>
              )}
            </div>
          </div>
        </div>
      )}
      {step == 1 && (
        <div>
          <div
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "30px",
              color: "#011D9B",
            }}
          >
            Create Your Account
          </div>
          <div style={{ marginLeft: "30px" }}>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#011D9B",
                }}
              >
                General Informations
              </div>
              <input
                type="text"
                name="FirstName"
                placeholder="First Name*"
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "80%",
                  marginBottom: "10px",
                }}
                onChange={(e) =>
                  setUserInfo((prev) => ({
                    ...prev,
                    FirstName: e.target.value,
                  }))
                }
              />
              <input
                type="text"
                name="LastName"
                placeholder="Last Name*"
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "80%",
                  marginBottom: "10px",
                }}
                onChange={(e) =>
                  setUserInfo((prev) => ({ ...prev, LastName: e.target.value }))
                }
              />
              <input
                type="tel"
                name="PhoneNumber"
                placeholder="Phone Number*"
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "80%",
                  marginBottom: "10px",
                }}
                onChange={(e) =>
                  setUserInfo((prev) => ({
                    ...prev,
                    PhoneNumber: e.target.value,
                  }))
                }
              />
              <input
                type="email"
                name="Email"
                placeholder="Email*"
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "80%",
                  marginBottom: "10px",
                }}
                onChange={(e) =>
                  setUserInfo((prev) => ({ ...prev, Email: e.target.value }))
                }
              />
              <select
                name="Genre"
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "80%",
                  marginBottom: "10px",
                }}
                onChange={(e) =>
                  setUserInfo((prev) => ({ ...prev, Genre: e.target.value }))
                }
              >
                <option value="">Select Gender*</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Non-binary">Non-binary</option>
                <option value="Non-binary">Transgender Man</option>
                <option value="Non-binary">Transgender Woman</option>
                <option value="Non-binary">Queer</option>
                <option value="Other">Other</option>
              </select>

              <label
                htmlFor="DateOfBirth"
                style={{
                  display: "block",
                  marginBottom: "5px",
                  color: "gray",
                  fontSize: "12px",
                  paddingLeft: "5px",
                }}
              >
                Date of Birth*
              </label>
              <input
                type="date"
                name="DateOfBirth"
                id="DateOfBirth"
                placeholder="Date of Birth*"
                required
                title="Please enter your date of birth"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "80%",
                  marginBottom: "10px",
                }}
                onChange={(e) =>
                  setUserInfo((prev) => ({
                    ...prev,
                    DateOfBirth: e.target.value,
                  }))
                }
              />

              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#011D9B",
                }}
              >
                Akasha Profile
              </div>
              <input
                type="text"
                name="MemberPseudo"
                placeholder="Akasha Username*"
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "80%",
                  marginBottom: "10px",
                }}
                onChange={(e) =>
                  setUserInfo((prev) => ({
                    ...prev,
                    MemberPseudo: e.target.value,
                  }))
                }
              />
              <div
                style={{
                  display: "flex",
                  padding: "10px",
                  color: "#011D9B",
                  width: "80%",
                  marginBottom: "10px",
                }}
              >
                Your username will be your unique identifier within the Akasha
                community. You can share it with other members to register for
                events or book tables as a group.
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "15px",
                  justifyContent: "center",
                }}
              >
                <div
                  className="ButtonBack-FirstConnectionProcess"
                  onClick={() => setStep((step) => step - 1)}
                >
                  Back
                </div>

                {akashaPass.length > 5 ? (
                  <button type="submit" className="ConfirmButtonNormalMode">
                    Confirm
                  </button>
                ) : (
                  <div className="ConfirmButtonDenieMode">Confirm</div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      {step == 2 && (
        <div>
          <div
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "30px",
              color: "#011D9B",
            }}
          >
            Create Your Password
          </div>
          <div style={{ marginLeft: "30px" }}>
            <form onSubmit={handleSubmitFormPass}>
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#011D9B",
                }}
              >
                Security
              </div>
              <input
                type="password"
                name="PassWord"
                placeholder="Password*"
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "80%",
                  marginBottom: "10px",
                }}
                onChange={(e) =>
                  setUserInfo((prev) => ({ ...prev, PassWord: e.target.value }))
                }
              />
              <input
                type="password"
                name="ConfirmPassWord"
                placeholder="Confirm Password*"
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "80%",
                  marginBottom: "10px",
                }}
                onChange={(e) =>
                  setUserInfo((prev) => ({
                    ...prev,
                    ConfirmPassWord: e.target.value,
                  }))
                }
              />
              {isValidPAssWord !== "" && (
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    marginTop: "10px",
                    width: "80%",
                  }}
                >
                  {isValidPAssWord}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "15px",
                }}
              >
                <div
                  className="ButtonBack-FirstConnectionProcess"
                  onClick={() => setStep((step) => step - 1)}
                >
                  Back
                </div>
                {akashaPass.length > 5 ? (
                  <button type="submit" className="ConfirmButtonNormalMode">
                    Confirm
                  </button>
                ) : (
                  <div className="ConfirmButtonDenieMode">Confirm</div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      {/*step == 3 && (
        <div>
          <div className="ChooseYourMemberShipText">Choose Your Membership</div>
          <div className="ContenerMembershipDetail-FirstConnectionPage">
            <div
              className={
                SelectedMembership === "Traveler"
                  ? "MembershipCardSelected-FirstConnectionPage"
                  : "MembershipCard"
              }
              style={{ marginRight: "20px" }}
              onClick={() => setSelectedMembership("Traveler")}
            >
              <div className="TitleMembershipCard">Traveler</div>
              <div>Lorem ipsum odor amet, consectetuer adipiscing elit.</div>
              <div className="endMembership">-</div>
              <div className="ButtonUpgradeMembership">Select plan</div>
            </div>
            <div
              className={
                SelectedMembership === "Explorer"
                  ? "MembershipCardSelected-FirstConnectionPage"
                  : "MembershipCard"
              }
              style={{ marginRight: "20px" }}
              onClick={() => setSelectedMembership("Explorer")}
            >
              <div className="TitleMembershipCard">Explorer</div>
              <div>Lorem ipsum odor amet, consectetuer adipiscing elit.</div>
              <div className="endMembership">-</div>
              <div className="ButtonUpgradeMembership">Select plan</div>
            </div>
            <div
              className={
                SelectedMembership === "KeyHolder"
                  ? "MembershipCardSelected-FirstConnectionPage"
                  : "MembershipCard"
              }
              style={{ marginRight: "20px" }}
              onClick={() => setSelectedMembership("KeyHolder")}
            >
              <div className="TitleMembershipCard">KeyHolder</div>
              <div>Lorem ipsum odor amet, consectetuer adipiscing elit.</div>
              <div className="endMembership">-</div>
              <div className="ButtonUpgradeMembership">Select plan</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "50px",
                }}
              >
                <input
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                  onChange={(e) => setAcceptCGU((prev) => !prev)}
                />
                I accept{" "}
                <a
                  href="https://www.akashasociety.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginLeft: "10px",
                    borderBottom: "solid 1px #011D9B",
                    color: "#011D9B",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  CGU’s
                </a>
              </label>
            </div>

            {success !== "" && (
              <div
                style={{
                  textAlign: "center",
                  color: "green",
                  marginTop: "10px",
                }}
              >
                {success}
              </div>
            )}
            {error !== "" && (
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "10px",
                }}
              >
                {error}
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "15px",
              }}
            >
              <div
                className="ButtonBack-FirstConnectionProcess"
                onClick={() => setStep((step) => step - 1)}
              >
                Back
              </div>
              {SelectedMembership !== "" && AcceptCGU ? (
                <div
                  className="ConfirmButtonNormalMode"
                  onClick={handleCreateNewUser}
                >
                  {" "}
                  Confirm
                </div>
              ) : (
                <div className="ConfirmButtonDenieMode"> Confirm</div>
              )}
            </div>
          </div>
        </div>
      )*/}
    </div>
  );
}

export default FirstConnectionProcess;
