import "./ForgotPassWord.css";
import React, { useState, useEffect } from "react";
import { auth } from "../../firebaseConfig"; // Assurez-vous d'avoir importé auth depuis firebase.js
import { sendPasswordResetEmail } from "firebase/auth";

function ForgotPassWord() {
  const [Mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async (e) => {
    try {
      await sendPasswordResetEmail(auth, Mail);
      setMessage("Password reset email sent! Check your inbox.");
      setError("");
    } catch (error) {
      setMessage("");
      setError("Failed to send password reset email. Please try again.");
      console.error("Error resetting password:", error);
    }
  };
  return (
    <div className="ForgotPasswordContener">
      <img
        src="/images/V2LogoAkasha.png"
        style={{ width: "150px", marginBottom: "30px" }}
      />
      <div
        style={{ color: "#011D9B", marginBottom: "20px", fontWeight: "bold" }}
      >
        Reset Your Password
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <input
          type="Mail"
          name="Mail"
          placeholder="Your Email"
          style={{
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #011D9B",
            color: "#011D9B",
            marginRight: "10px", // Espace horizontal entre l'input et le bouton
          }}
          onChange={(e) => setMail(e.target.value)}
        />
        <div className="ResetPassWordButton" onClick={handlePasswordReset}>
          Reset
        </div>
      </div>

      {message && <p style={{ color: "green" }}>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      <div
        style={{
          color: "#011D9B",
          maxWidth: "500px",
        }}
      >
        If your email is registered in our database, you'll receive a message
        shortly with instructions to reset your password.
      </div>
      <div className="ButtonGoBackResetPAssWord">Back</div>
    </div>
  );
}

export default ForgotPassWord;
