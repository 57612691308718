import React, { useState } from "react";
import Registration from "../Authentification/Authentification";
import { BorderBottom } from "@mui/icons-material";
import "./HeaderBackOffice.css";

const HeaderBackOffice = ({ menuChoice, setmenuChoice }) => {
  return (
    <div className="contenerHeaderBackOffice">
      {<Registration />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "45px",
        }}
      >
        <img
          src={"/images/LogoNoText.png"}
          alt="Avatar"
          style={{
            width: "60px",
            marginRight: "50px",
            cursor: "pointer",
          }}
          onClick={() => setmenuChoice(0)}
        />
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <img
          src={"/images/Generic-avatar.png"}
          alt="Avatar"
          style={{
            width: "33px",
            height: "33px",
            marginRight: "50px",
            cursor: "pointer",
          }}
          onClick={() => setmenuChoice(4)}
        />
      </div>
    </div>
  );
};

export default HeaderBackOffice;
