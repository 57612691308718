import React, { useState } from "react";
import { updatePassword } from "firebase/auth";
import { auth } from "../../../../firebaseConfig"; // Assurez-vous que le chemin est correct

const UpdatePassWord = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const user = auth.currentUser;
      if (user) {
        await updatePassword(user, newPassword);
        setConfirmNewPassword("");
        setNewPassword("");
        setCurrentPassword("");
        setSuccess("Password updated successfully");
      } else {
        setError("No user is logged in");
      }
    } catch (error) {
      console.error(error);
      setError("Failed to update password: " + error.message);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <form onSubmit={handleChangePassword}>
        <div
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            marginTop: "50px",
            marginBottom: "30px",
            color: "#011D9B",
          }}
        >
          Change Your Password
        </div>
        {error && <div style={{ color: "red" }}>{error}</div>}
        {success && <div style={{ color: "green" }}>{success}</div>}
        <div style={{ marginLeft: "30px" }}>
          <input
            type="password"
            name="currentPassword"
            placeholder="Current Password*"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              width: "80%",
              marginBottom: "10px",
            }}
            required
          />
          <input
            type="password"
            name="newPassword"
            placeholder="New Password*"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              width: "80%",
              marginBottom: "10px",
            }}
            required
          />
          <input
            type="password"
            name="confirmNewPassword"
            placeholder="Confirm New Password*"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              width: "80%",
              marginBottom: "10px",
            }}
            required
          />
          <button
            type="submit"
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "none",
              backgroundColor: "#011D9B",
              color: "#fff",
              width: "80%",
              cursor: "pointer",
            }}
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdatePassWord;
