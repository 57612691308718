// BookTableSanctuaryMobileMode.jsx
import React, { useState } from "react";
import "./BookTableSanctuaryMobileMode.css"; // Importez le fichier CSS pour les styles
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { CreateNewSanctuaryBooking } from "../../../Logic/HandleClickFunctions";
import { FaCirclePlus } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";

const BookTableSanctuaryMobileMode = ({ UserID, setmenuChoice }) => {
  const [activeTab, setActiveTab] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);

  const [ComeWithGuest, setComeWithGuest] = useState(0);
  const [YourGuestIsAMember, setyourGuestIsAMember] = useState(0);
  const [ListOfGuestMember, setListOfGuestMember] = useState([""]);
  const [ListOfGuestNoMember, setListOfGuestNoMember] = useState([]);

  const [step, setStep] = useState(1);

  const availableTimes = {
    "2024-11-07": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-08": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-09": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-10": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-14": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-15": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-16": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-17": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],

    "2024-11-21": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-22": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-23": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-24": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-28": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-29": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-11-30": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
  };
  const handleTabClick = (tabIndex) => {
    setActiveTab(activeTab === tabIndex ? null : tabIndex);
  };

  const formatDate = (date) => date.toISOString().split("T")[0];

  const handleDateSelect = (date) => {
    const dateWithTime = new Date(date);
    dateWithTime.setHours(5, 5, 5, 5);
    const formattedDate = formatDate(dateWithTime);

    if (availableTimes[formattedDate]) {
      setSelectedTime(null);
      setSelectedDate(formattedDate);
      setIsDateSelected(true);
    } else {
      alert("Aucun créneau disponible pour cette date.");
    }
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };
  return (
    <div style={{ marginTop: "30px" }}>
      {/* Onglet 1 */}
      <div
        onClick={() => handleTabClick(1)}
        className={activeTab === 1 ? "OngletOpen" : "Onglet"}
      >
        Select a date
      </div>
      <div className={`content ${activeTab === 1 ? "open" : ""}`}>
        <DatePicker
          selected={selectedDate ? new Date(selectedDate) : null}
          onChange={handleDateSelect}
          inline
          className="custom-datepicker" // Ajoutez une classe personnalisée si nécessaire
          highlightDates={Object.keys(availableTimes).map(
            (date) => new Date(date)
          )}
          minDate={new Date()} // Disable past dates
          placeholderText="Sélectionnez une date"
        />
      </div>

      {/* Onglet 2 */}
      <div onClick={() => handleTabClick(2)} className="Onglet">
        Pick your time
      </div>
      <div className={`content ${activeTab === 2 ? "open" : ""}`}>
        <div
          style={{
            marginLeft: "50px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ height: "60px" }}></div>
          <div className="step">
            <div style={{ height: "400px" }}>
              <h3>Choisissez une heure pour le {selectedDate}</h3>
              <div className="time-selection">
                {selectedDate &&
                  availableTimes[selectedDate].map((time) => (
                    <button
                      key={time}
                      onClick={() => handleTimeSelect(time)}
                      className={`time-button ${
                        selectedTime === time ? "selected" : ""
                      }`}
                    >
                      {time}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Onglet 3 */}
      <div onClick={() => handleTabClick(3)} className="Onglet">
        Bring your guest
      </div>
      <div className={`content ${activeTab === 3 ? "open" : ""}`}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {step !== 5 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
              }}
            >
              Will you come with guest ?
              <label style={{ marginLeft: "10px", marginRight: "10px" }}>
                <input
                  type="radio"
                  name="tableOption"
                  value="inside"
                  onChange={() => {
                    setComeWithGuest(1);
                    setStep(3);
                  }}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="tableOption"
                  value="outside"
                  onChange={() => {
                    setComeWithGuest(2);
                    setStep(2);
                  }}
                />
                No
              </label>
            </div>
          )}
          {ComeWithGuest === 2 && step !== 5 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <div className="ButtonBackCalendarMobileMode">Retour</div>
              <div
                onClick={() => {
                  CreateNewSanctuaryBooking(
                    UserID,
                    selectedDate,
                    selectedTime,
                    ListOfGuestMember
                  );
                  setStep(5);
                }}
                className="ButtonNextCalendarMobileMode"
              >
                confirm
              </div>
            </div>
          )}
          {(step === 3 || step === 4) && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                Your guest is a member ?
                <label style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="tableOption"
                    value="inside"
                    onChange={() => {
                      setyourGuestIsAMember(1);
                      setStep(4);
                    }}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="tableOption"
                    value="outside"
                    onChange={() => {
                      setyourGuestIsAMember(2);
                      setStep(3);
                    }}
                  />
                  No
                </label>
              </div>
            </>
          )}
          {step === 4 && (
            <>
              <div></div>
              <div className="step">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ width: "250px" }}>Fill their alias : </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                    }}
                  >
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {ListOfGuestMember.map((member, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <label>
                            <input
                              type="text"
                              name={member}
                              placeholder="Name"
                              value={member}
                              onChange={(e) => {
                                // Créer une copie de ListOfGuestMember et mettre à jour l'élément
                                const updatedList = [...ListOfGuestMember];
                                updatedList[index] = e.target.value;
                                setListOfGuestMember(updatedList); // Utiliser setListOfGuestMember pour mettre à jour l'état
                              }}
                              style={{
                                padding: "5px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                              }}
                            />
                          </label>
                          <FaTrashAlt
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            onClick={() => {
                              // Supprime l'élément à l'index donné
                              const updatedList = ListOfGuestMember.filter(
                                (_, i) => i !== index
                              );
                              setListOfGuestMember(updatedList); // Met à jour l'état sans l'élément supprimé
                            }}
                          />
                        </div>
                      ))}

                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setListOfGuestMember((prev) => [...prev, []])
                        }
                      >
                        {" "}
                        <FaCirclePlus color={"#E35508"} size={25} />{" "}
                        <div style={{ marginLeft: "10px" }}>Add more</div>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "50vw",
                    justifyContent: "space-between",
                    marginTop: "40px",
                    marginLeft: "150px",
                  }}
                >
                  <div
                    onClick={() => setStep(step - 1)}
                    className="ButtonBackCalendarMobileMode"
                  >
                    Retour
                  </div>
                  <div
                    onClick={() => {
                      setStep(step + 1);
                      CreateNewSanctuaryBooking(
                        UserID,
                        selectedDate,
                        selectedTime,
                        ListOfGuestMember
                      );
                      setSelectedDate(null);
                      setSelectedTime(null);
                      setIsDateSelected(false);
                      setComeWithGuest(0);
                      setyourGuestIsAMember(0);
                      setListOfGuestMember([""]);
                      setListOfGuestNoMember([]);
                    }}
                    className="ButtonNextCalendarMobileMode"
                  >
                    confirm
                  </div>
                </div>
              </div>
            </>
          )}
          {step === 5 && (
            <>
              <div></div>
              <div className="step">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/images/V2LogoAkasha.png"
                    style={{ width: "200px" }}
                  />
                  <div
                    style={{
                      color: "#011D9B",
                      marginTop: "30px",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    Thank you !
                  </div>
                  <div
                    style={{
                      color: "#011D9B",
                      marginTop: "30px",

                      fontSize: "25px",
                    }}
                  >
                    You will receive an email as soon as your reservation is
                    confirmed
                  </div>
                  <div
                    className="ButtonGoBackToHomePage"
                    onClick={() => setmenuChoice(0)}
                  >
                    Go back to homepage
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookTableSanctuaryMobileMode;
