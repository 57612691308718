import React, { useState, useEffect } from "react";
import "./BookAClassMobileMode.css";
import { GetListWelnessEventForTheWeek } from "../../../Logic/HandleClickFunctions";
import ModalBookAClass from "../ModalBookAClass/ModalBookAClass";

// Composant pour le planning des ateliers
const BookAClassMobileMode = ({ UserID }) => {
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek());
  const [workshops, setWorkshops] = useState([]);
  const [activitiesType, setActivitiesType] = useState([
    "Yoga",
    "Breathwork",
    "Sound Bath",
    "Pilates",
    "Pilates",
    "Pilates",
  ]);
  const [SelectedDay, setSelectedDay] = useState("Monday");
  console.log(workshops);

  // Générer la semaine actuelle (du lundi au dimanche)
  function getCurrentWeek() {
    const current = new Date();
    const firstDayOfWeek = current.getDate() - current.getDay() + 1;
    const startOfWeek = new Date(current.setDate(firstDayOfWeek));
    const week = [];

    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      week.push(day);
    }
    return week;
  }

  // Aller à la semaine précédente
  const previousWeek = () => {
    const newWeek = currentWeek.map((day) => {
      const newDay = new Date(day);
      newDay.setDate(day.getDate() - 7);
      return newDay;
    });
    setCurrentWeek(newWeek);
  };

  // Aller à la semaine suivante
  const nextWeek = () => {
    const newWeek = currentWeek.map((day) => {
      const newDay = new Date(day);
      newDay.setDate(day.getDate() + 7);
      return newDay;
    });
    setCurrentWeek(newWeek);
  };

  // Charger les ateliers (simulé ici avec un useEffect)

  useEffect(() => {
    const fetchWorkshops = async () => {
      // Appel asynchrone pour obtenir les ateliers
      const sampleWorkshops2 = [
        {
          day: "Monday",
          hour: "MORNING",
          duration: "08:00 - 09:00",
          title: "Atelier de Peinture",
          FacilitatorName: "Facilitator name",
        },
        {
          day: "Monday",
          hour: "MORNING",
          duration: "08:00 - 09:00",
          title: "Atelier de Peinture",
          FacilitatorName: "Facilitator name",
        },
        {
          day: "Wednesday",
          hour: "AFTERNOON",
          duration: "08:00 - 09:00",
          title: "Cours de Cuisine",
          FacilitatorName: "Facilitator name",
        },
        {
          day: "Friday",
          hour: "AFTERNOON",
          duration: "08:00 - 09:00",
          title: "Atelier de Programmation",
          FacilitatorName: "Facilitator name",
        },
        {
          day: "Friday",
          hour: "EVENING",
          duration: "08:00 - 09:00",
          title: "Atelier de Programmation",
          FacilitatorName: "Facilitator name",
        },
      ];
      const sampleWorkshops = await GetListWelnessEventForTheWeek(currentWeek);
      console.log(sampleWorkshops);
      setWorkshops(sampleWorkshops);
    };

    fetchWorkshops();
  }, [currentWeek]);

  // Obtenir les jours de la semaine sous forme de noms
  const getDayName = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  // Crée une grille de 7 colonnes et des lignes horaires
  const renderGrid = () => {
    const hours = ["MORNING", "AFTERNOON", "EVENING"];

    return (
      <div className="Grid-MobileModeBookAClass">
        {workshops.map(
          (workshop) =>
            workshop.Day === SelectedDay && (
              <div className="workshopContener-MobileModeBookAClass">
                <div className="rowworkshop-MobileModeBookAClass">
                  {workshop.title}
                </div>
                <div className="rowworkshop-MobileModeBookAClass">
                  {workshop.Duration}
                </div>
                <div className="rowworkshop-MobileModeBookAClass">
                  {workshop.FacilitatorName}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div></div>
                  <div>
                    <ModalBookAClass CalssData={workshop} UserID={UserID} />
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    );
  };

  return (
    <>
      <div className="workshop-scheduler-Mobile">
        <h2
          style={{
            color: "#01D595",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "28px",
          }}
        >
          Book a class
        </h2>
        {/* Boutons de navigation */}
        <div className="navigation">
          <div className="navigationdivMobileModeBookAClass">
            <div onClick={previousWeek} style={{ cursor: "pointer" }}>
              {"<"}
            </div>
            <div>
              Mon. {currentWeek[0].getDate()}/{currentWeek[0].getMonth() + 1} -
              Sun. {currentWeek[6].getDate()}/{currentWeek[6].getMonth() + 1}
            </div>
            <div onClick={nextWeek} style={{ cursor: "pointer" }}>
              {">"}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          {/*<div
            style={{
              padding: "15px",
              paddingLeft: "50px",
              paddingRight: "50px",
              borderRadius: "5px",
              border: "solid 1px #C8C2B8",
              marginRight: "15px",
              color: "white",
              backgroundColor: "#01D595",
            }}
          >
            See All
          </div>
          {activitiesType.map((activity) => (
            <div className="ActivityTypeSelection">{activity}</div>
          ))}*/}
        </div>

        {/* Grille de la semaine */}
        <div className="grid-MobileModeBookAClass">
          <div className="row-MobileModeBookAClass header-MobileModeBookAClass">
            {currentWeek.map((day, index) => (
              <div
                key={index}
                className="cell-MobileModeBookAClass"
                onClick={() => setSelectedDay(() => getDayName(day))}
              >
                <div style={{ marginBottom: "5px" }}>
                  {getDayName(day).substring(0, 3)} <br />{" "}
                </div>
                <div
                  style={{
                    ...(getDayName(day) === SelectedDay && {
                      backgroundColor: "#01D595",
                      padding: "5px",
                      borderRadius: "12px",
                      color: "white",
                    }),
                  }}
                >
                  {day.toLocaleDateString("fr-FR").substring(0, 2)}
                </div>
              </div>
            ))}
          </div>
          {renderGrid()}
        </div>
      </div>
    </>
  );
};

export default BookAClassMobileMode;
