import React, { useState, useEffect } from "react";
import "./DisplaySocialEventForFackOffice.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Rejected") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

const DisplaySocialEventForFackOffice = ({
  SetSelectedEvent,
  SelectedEvent,
  Data,
}) => {
  return (
    <div className="ContenerDisplaySocialEventForFackOffice">
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Event Name</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Duration</TableCell>
              <TableCell align="left">Number of registered</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {Data != null &&
              Data.ListeSocialEventWithData.map((row) => (
                <TableRow
                  key={row.title}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => SetSelectedEvent(row)}
                  style={
                    SelectedEvent && SelectedEvent.ID === row.ID
                      ? { backgroundColor: "blueviolet" }
                      : { backgroundColor: "white" }
                  }
                >
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell align="left">{row.Day}</TableCell>
                  <TableCell align="left">{row.Duration}</TableCell>

                  <TableCell align="left">
                    {row.ListeParticipant.length}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DisplaySocialEventForFackOffice;
