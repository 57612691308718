import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { HandleBookSocialevent } from "../../../../Logic/HandleClickFunctions";
import "./ModalBookSocialEventComputerMode.css";

const ModalBookSocialEventComputerMode = ({ SocialEventData, UserID }) => {
  const [open, setOpen] = useState(false);
  const [ModalMode, setModalMode] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div onClick={handleOpen} style={{ cursor: "pointer" }}>
        BOOK
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 0, // Enlever padding de Box
            borderRadius: "8px",
          }}
        >
          {ModalMode === 0 && (
            <div
              style={{
                padding: "25px",
                backgroundColor: "#494949",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "white",
                }}
              >
                <div style={{ fontSize: "22px", marginBottom: "10px" }}>
                  {SocialEventData.title}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <span style={{ marginRight: "30px" }}>
                    {SocialEventData.Date}
                  </span>{" "}
                  {SocialEventData.Duration}
                </div>
                <div>Location</div>
              </div>
            </div>
          )}

          {ModalMode === 0 && (
            <Box sx={{ padding: 3 }}>
              <Typography id="modal-title" variant="h6" component="h2">
                Description
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                {SocialEventData.Description}
              </Typography>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                style={{ marginTop: "25px" }}
              >
                Facilitator / Instructor
              </Typography>
              <Typography
                id="modal-description"
                sx={{ mt: 2, marginBottom: "20px" }}
              >
                {SocialEventData.FacilitatorName}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginRight: "20px",
                  borderTop: "solid 2px #D9D9D9",
                }}
              >
                <div></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "15px",
                  }}
                >
                  <div
                    onClick={handleClose}
                    className="ButtonCloseModalBookAClass"
                  >
                    CLOSE
                  </div>
                  <div
                    onClick={() => {
                      HandleBookSocialevent(UserID, SocialEventData.ID);
                      setModalMode(1);
                    }}
                    className="ButtonBookASocialEvent"
                  >
                    BOOK
                  </div>
                </div>
              </div>
            </Box>
          )}

          {ModalMode === 1 && (
            <Box sx={{ padding: 3 }}>
              <div className="step">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/images/V2LogoAkasha.png"
                    style={{ width: "200px" }}
                  />
                  <div
                    style={{
                      color: "#011D9B",
                      marginTop: "30px",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    Thank you !
                  </div>
                  <div
                    style={{
                      color: "#011D9B",
                      marginTop: "30px",

                      fontSize: "25px",
                    }}
                  >
                    You will receive an email as soon as your reservation is
                    confirmed
                  </div>
                  {
                    <div
                      style={{
                        textAlign: "center",
                        width: "150px",
                        padding: "15px",
                        marginTop: "10px",
                        backgroundColor: "#603D88",
                        borderRadius: "5px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClose()}
                    >
                      Close
                    </div>
                  }
                </div>
              </div>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ModalBookSocialEventComputerMode;
