import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  getFirestore,
  query,
  orderBy,
  limit,
  collection,
  getDocs,
  addDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { firestore, storage, app } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";

export async function RejectBookingSocialEvent(eventID, UserID) {
  try {
    const BookingValidationDocRef = doc(
      firestore,
      "Akasha",
      "BookingValidationByAdmin"
    );
    const BookingValidationSnapshot = await getDoc(BookingValidationDocRef);
    let SocialEventValidation =
      BookingValidationSnapshot.data().SocialEventConfirmation;

    const SocialEventValidationExist = SocialEventValidation.findIndex(
      (event) => event.IDSocialEvent === eventID && event.userID === UserID
    );

    if (SocialEventValidationExist !== -1) {
      // Si l'objet est trouvé, mettre à jour la propriété souhaitée
      SocialEventValidation[SocialEventValidationExist].State = "Rejected";
    } else {
      SocialEventValidation.push({
        IDSocialEvent: eventID,
        State: "Rejected",
        userID: UserID,
      });
    }

    await updateDoc(BookingValidationDocRef, {
      SocialEventConfirmation: SocialEventValidation,
    });

    console.log(SocialEventValidationExist);
  } catch (error) {
    console.error("Error updating user data:", error);
    throw new Error("Error updating user data");
  }
}
export async function RejectBookingWellnessEvent(eventID, UserID) {
  try {
    const BookingValidationDocRef = doc(
      firestore,
      "Akasha",
      "BookingValidationByAdmin"
    );
    const BookingValidationSnapshot = await getDoc(BookingValidationDocRef);
    let SocialEventValidation =
      BookingValidationSnapshot.data().WelnessEventConfirmation;

    const SocialEventValidationExist = SocialEventValidation.findIndex(
      (event) => event.IDWelnessEvent === eventID && event.userID === UserID
    );

    if (SocialEventValidationExist !== -1) {
      // Si l'objet est trouvé, mettre à jour la propriété souhaitée
      SocialEventValidation[SocialEventValidationExist].State = "Rejected";
    } else {
      SocialEventValidation.push({
        IDWelnessEvent: eventID,
        State: "Rejected",
        userID: UserID,
      });
    }

    await updateDoc(BookingValidationDocRef, {
      WelnessEventConfirmation: SocialEventValidation,
    });

    console.log(SocialEventValidationExist);
  } catch (error) {
    console.error("Error updating user data:", error);
    throw new Error("Error updating user data");
  }
}
export async function ApprovedBookingSocialEvent(eventID, UserID) {
  try {
    const BookingValidationDocRef = doc(
      firestore,
      "Akasha",
      "BookingValidationByAdmin"
    );
    const BookingValidationSnapshot = await getDoc(BookingValidationDocRef);
    let SocialEventValidation =
      BookingValidationSnapshot.data().SocialEventConfirmation;

    const SocialEventValidationExist = SocialEventValidation.findIndex(
      (event) => event.IDSocialEvent === eventID && event.userID === UserID
    );

    if (SocialEventValidationExist !== -1) {
      // Si l'objet est trouvé, mettre à jour la propriété souhaitée
      SocialEventValidation[SocialEventValidationExist].State = "Approved";
    } else {
      SocialEventValidation.push({
        IDSocialEvent: eventID,
        State: "Approved",
        userID: UserID,
      });
    }

    await updateDoc(BookingValidationDocRef, {
      SocialEventConfirmation: SocialEventValidation,
    });

    console.log(SocialEventValidationExist);
  } catch (error) {
    console.error("Error updating user data:", error);
    throw new Error("Error updating user data");
  }
}
export async function ApprovedBookingWellnessEvent(eventID, UserID) {
  try {
    const BookingValidationDocRef = doc(
      firestore,
      "Akasha",
      "BookingValidationByAdmin"
    );
    const BookingValidationSnapshot = await getDoc(BookingValidationDocRef);
    let SocialEventValidation =
      BookingValidationSnapshot.data().WelnessEventConfirmation;

    const SocialEventValidationExist = SocialEventValidation.findIndex(
      (event) => event.IDWelnessEvent === eventID && event.userID === UserID
    );

    if (SocialEventValidationExist !== -1) {
      // Si l'objet est trouvé, mettre à jour la propriété souhaitée
      SocialEventValidation[SocialEventValidationExist].State = "Approved";
    } else {
      SocialEventValidation.push({
        IDWelnessEvent: eventID,
        State: "Approved",
        userID: UserID,
      });
    }

    await updateDoc(BookingValidationDocRef, {
      WelnessEventConfirmation: SocialEventValidation,
    });

    console.log(SocialEventValidationExist);
  } catch (error) {
    console.error("Error updating user data:", error);
    throw new Error("Error updating user data");
  }
}
export async function DataAnalyseUseOfThePlatform() {
  try {
    const docRefconfirmedBookingByAdmin = doc(
      firestore,
      "Akasha",
      "BookingValidationByAdmin"
    );
    const docSnapconfirmedBookingByAdmin = await getDoc(
      docRefconfirmedBookingByAdmin
    );
    const SanctuaryBookingConfirmation =
      docSnapconfirmedBookingByAdmin.data().SanctuaryBookingConfirmation;
    const SocialEventConfirmation =
      docSnapconfirmedBookingByAdmin.data().SocialEventConfirmation;

    const WelnessEventConfirmation =
      docSnapconfirmedBookingByAdmin.data().WelnessEventConfirmation;

    const usersCollection = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersCollection);

    let UserMadeRegistration = [];
    let NBHomme = 0;
    let NBFemme = 0;
    usersSnapshot.forEach((doc) => {
      if (doc.data().hasOwnProperty("userData")) {
        UserMadeRegistration.push(
          `${doc.data().userData.FirstName} ${doc.data().userData.LastName}`
        );
        if (doc.data().userData.Genre === "Female") {
          NBFemme = NBFemme + 1;
        } else if (doc.data().userData.Genre === "Male") {
          NBHomme = NBHomme + 1;
        }
      }
    });

    let ParticipantOpening = [];
    const socialDocRef = doc(firestore, "Akasha", "SocialEventPlanning");
    const socialSnapshot = await getDoc(socialDocRef);
    const socialEventData = socialSnapshot.data();
    socialEventData.ListEvents[0].ListeParticipant.forEach((participant) => {
      usersSnapshot.forEach((doc) => {
        if (doc.id === participant) {
          ParticipantOpening.push(
            `${doc.data().userData.FirstName} ${doc.data().userData.LastName}`
          );
        }
      });
    });

    const SocialEventDocRef = doc(firestore, "Akasha", "SocialEventPlanning");
    const SocialEventSnapshot = await getDoc(SocialEventDocRef);
    const ListeSocialEvent = SocialEventSnapshot.data().ListEvents;

    let ListeSocialEventWithData = [];
    ListeSocialEvent.forEach((event) => {
      let listeParcipantWithData = [];
      event.ListeParticipant.forEach((participant) => {
        usersSnapshot.forEach((doc) => {
          if (doc.data().uid === participant) {
            let isConfirmedByAdmin = SocialEventConfirmation.find(
              (bookingConfirmation) =>
                bookingConfirmation.userID === participant &&
                bookingConfirmation.IDSocialEvent === event.ID &&
                bookingConfirmation.State === "Approved"
            );

            let isCancelByAdmin = SocialEventConfirmation.find(
              (bookingConfirmation) =>
                bookingConfirmation.userID === participant &&
                bookingConfirmation.IDSocialEvent === event.ID &&
                bookingConfirmation.State === "Rejected"
            );

            isConfirmedByAdmin &&
              listeParcipantWithData.push({
                ...doc.data(),
                Status: "Approved",
              });

            isCancelByAdmin &&
              listeParcipantWithData.push({
                ...doc.data(),
                Status: "Rejected",
              });

            !isConfirmedByAdmin &&
              !isCancelByAdmin &&
              listeParcipantWithData.push({
                ...doc.data(),
                Status: "Pending",
              });
          }
        });
      });
      ListeSocialEventWithData.push({
        ...event,
        ListeParticipant: listeParcipantWithData,
      });
    });

    const WelnessEventDocRef = doc(firestore, "Akasha", "WelnessEventPlanning");
    const WelnessEventSnapshot = await getDoc(WelnessEventDocRef);
    const ListeWelnessEvent = WelnessEventSnapshot.data().ListEvents;

    let ListeWelnessEventWithData = [];
    ListeWelnessEvent.forEach((event) => {
      let listeParcipantWithData = [];
      event.ListeParticipant.forEach((participant) => {
        usersSnapshot.forEach((doc) => {
          if (doc.data().uid === participant) {
            let isConfirmedByAdmin = WelnessEventConfirmation.find(
              (bookingConfirmation) =>
                bookingConfirmation.userID === participant &&
                bookingConfirmation.IDWelnessEvent === event.ID &&
                bookingConfirmation.State === "Approved"
            );

            let isCancelByAdmin = WelnessEventConfirmation.find(
              (bookingConfirmation) =>
                bookingConfirmation.userID === participant &&
                bookingConfirmation.IDWelnessEvent === event.ID &&
                bookingConfirmation.State === "Rejected"
            );

            isConfirmedByAdmin &&
              listeParcipantWithData.push({
                ...doc.data(),
                Status: "Approved",
              });

            isCancelByAdmin &&
              listeParcipantWithData.push({
                ...doc.data(),
                Status: "Rejected",
              });

            !isConfirmedByAdmin &&
              !isCancelByAdmin &&
              listeParcipantWithData.push({
                ...doc.data(),
                Status: "Pending",
              });
          }
        });
      });
      ListeWelnessEventWithData.push({
        ...event,
        ListeParticipant: listeParcipantWithData,
      });
    });

    console.log(ParticipantOpening);
    console.log(UserMadeRegistration);
    return {
      ParticipantOpening,
      UserMadeRegistration,
      NBFemme,
      NBHomme,
      ListeSocialEventWithData,
      ListeWelnessEventWithData,
    };
  } catch (error) {
    console.error("Error updating user documents: ", error);
  }
}

export async function GetSelectedSocialEventForValidationParticipant(
  SelectedEventID
) {
  try {
    const Data = await DataAnalyseUseOfThePlatform();
    const SelectedEvent = Data.ListeSocialEventWithData.find(
      (event) => event.ID === SelectedEventID
    );

    return SelectedEvent;
  } catch (error) {
    console.error("Error : ", error);
  }
}
export async function GetSelectedWellnessEventForValidationParticipant(
  SelectedEventID
) {
  try {
    const Data = await DataAnalyseUseOfThePlatform();
    const SelectedEvent = Data.ListeWelnessEventWithData.find(
      (event) => event.ID === SelectedEventID
    );

    return SelectedEvent;
  } catch (error) {
    console.error("Error : ", error);
  }
}
export async function SetUserDataInscription(userId, userData) {
  try {
    const userDocRef = doc(firestore, "users", userId);
    console.log(
      "Updating user data for userId:",
      userId,
      "with data:",
      userData
    );
    await setDoc(userDocRef, {
      userData: { ...userData },
      firstConnectionV2app: true,
    });
    console.log("User data updated successfully");
  } catch (error) {
    console.error("Error updating user data:", error);
    throw new Error("Error updating user data");
  }
}

export async function HandleUpdateUserData(userId, userData) {
  try {
    const userDocRef = doc(firestore, "users", userId);
    const DocSnapshot = await getDoc(userDocRef);
    await updateDoc(userDocRef, {
      userData: { ...userData, Email: DocSnapshot.data().email },
      firstConnectionV2app: true,
    });
  } catch (error) {
    console.error("Error updating user data:", error);
    throw new Error("Error updating user data");
  }
}

export async function addFirstConnectionV2app() {
  try {
    const usersCollection = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersCollection);
    const updatePromises = [];

    usersSnapshot.forEach((doc) => {
      const userDocRef = doc.ref;
      const updatePromise = updateDoc(userDocRef, {
        firstConnectionV2app: false,
      });
      updatePromises.push(updatePromise);
    });

    await Promise.all(updatePromises);
    console.log("All user documents updated successfully.");
  } catch (error) {
    console.error("Error updating user documents: ", error);
  }
}
export async function CheckIfValidAkashaPass(AkashaPass) {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const AkashaPassSnapshot = await getDoc(AkashaPassDocRef);
  const allAkashaPass = AkashaPassSnapshot.data().AkashaPass;

  let IsAkashaPassExist = false;
  const SelectPass = allAkashaPass.filter((pass) => pass.pass === AkashaPass);
  if (SelectPass.length != 0 && SelectPass[0].validity === true) {
    IsAkashaPassExist = true;
  } else {
    IsAkashaPassExist = false;
  }
  return IsAkashaPassExist;
}

export async function HandleCreateAcountWithAkashaPass(AkashaPass) {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const AkashaPassSnapshot = await getDoc(AkashaPassDocRef);
  const allAkashaPass = AkashaPassSnapshot.data().AkashaPass;

  let IsAkashaPassExist = false;
  const SelectPass = allAkashaPass.filter((pass) => pass.pass === AkashaPass);
  if (SelectPass.length != 0 && SelectPass[0].validity === true) {
    IsAkashaPassExist = true;
  } else {
    IsAkashaPassExist = false;
  }

  if (IsAkashaPassExist) {
    const UpdateAkashaPass = allAkashaPass.map((pass) => {
      return pass.pass === AkashaPass
        ? { pass: pass.pass, validity: false }
        : pass;
    });
    await setDoc(AkashaPassDocRef, { AkashaPass: UpdateAkashaPass });
  }

  return IsAkashaPassExist;
}

export async function SetAkashaPassFunction() {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const Akashapass = [
    "Ihavedesire4#502340",
    "Ihavedesire4#184876",
    "Ihavedesire4#195207",
    "Ihavedesire4#167890",
    "Ihavedesire4#279520",
    "Ihavedesire4#328714",
    "Ihavedesire4#324289",
    "Ihavedesire4#066402",
    "Ihavedesire4#737166",
    "Ihavedesire4#133874",
    "Ihavedesire4#410000",
    "Ihavedesire4#228372",
    "Ihavedesire4#970424",
    "Ihavedesire4#485262",
    "Ihavedesire4#278410",
    "Ihavedesire4#947468",
    "Ihavedesire4#169983",
    "Ihavedesire4#559154",
    "Ihavedesire4#630090",
    "Ihavedesire4#344987",
    "Ihavedesire4#132956",
    "Ihavedesire4#601732",
    "Ihavedesire4#102871",
    "Ihavedesire4#853591",
    "Ihavedesire4#289710",
    "Ihavedesire4#181100",
    "Ihavedesire4#998770",
    "Ihavedesire4#800046",
    "Ihavedesire4#787086",
    "Ihavedesire4#132301",
    "Ihavedesire4#948477",
    "Ihavedesire4#706265",
    "Ihavedesire4#763329",
    "Ihavedesire4#979219",
    "Ihavedesire4#569900",
    "Ihavedesire4#777807",
    "Ihavedesire4#594883",
    "Ihavedesire4#728482",
    "Ihavedesire4#931280",
    "Ihavedesire4#284872",
    "Ihavedesire4#177554",
    "Ihavedesire4#311291",
    "Ihavedesire4#372855",
    "Ihavedesire4#365718",
    "Ihavedesire4#466542",
    "Ihavedesire4#371664",
    "Ihavedesire4#416962",
    "Ihavedesire4#339288",
    "Ihavedesire4#971173",
    "Ihavedesire4#107345",
    "Ihavedesire4#326085",
    "Ihavedesire4#250154",
    "Ihavedesire4#135980",
    "Ihavedesire4#706710",
    "Ihavedesire4#209979",
    "Ihavedesire4#339594",
    "Ihavedesire4#840510",
    "Ihavedesire4#278986",
    "Ihavedesire4#574593",
    "Ihavedesire4#443581",
    "Ihavedesire4#637297",
    "Ihavedesire4#017659",
    "Ihavedesire4#693982",
    "Ihavedesire4#341667",
    "Ihavedesire4#106656",
    "Ihavedesire4#273612",
    "Ihavedesire4#981864",
    "Ihavedesire4#363250",
    "Ihavedesire4#575757",
    "Ihavedesire4#268347",
    "Ihavedesire4#111669",
    "Ihavedesire4#339056",
    "Ihavedesire4#645040",
    "Ihavedesire4#052291",
    "Ihavedesire4#644597",
    "Ihavedesire4#541990",
    "Ihavedesire4#379909",
    "Ihavedesire4#103085",
    "Ihavedesire4#103819",
    "Ihavedesire4#701260",
    "Ihavedesire4#311978",
    "Ihavedesire4#505402",
    "Ihavedesire4#129174",
    "Ihavedesire4#771377",
    "Ihavedesire4#479760",
    "Ihavedesire4#187327",
    "Ihavedesire4#345956",
    "Ihavedesire4#790878",
    "Ihavedesire4#456684",
    "Ihavedesire4#881277",
    "Ihavedesire4#477688",
    "Ihavedesire4#141758",
    "Ihavedesire4#809692",
    "Ihavedesire4#977585",
    "Ihavedesire4#673984",
    "Ihavedesire4#247350",
    "Ihavedesire4#733610",
    "Ihavedesire4#258196",
    "Ihavedesire4#367577",
    "Ihavedesire4#214108",
    "Ihavedesire4#497530",
    "Ihavedesire4#917788",
    "Ihavedesire4#954244",
    "Ihavedesire4#589262",
    "Ihavedesire4#461734",
    "Ihavedesire4#814529",
    "Ihavedesire4#796606",
    "Ihavedesire4#161833",
    "Ihavedesire4#209121",
    "Ihavedesire4#486028",
    "Ihavedesire4#141021",
    "Ihavedesire4#973083",
    "Ihavedesire4#240248",
    "Ihavedesire4#090877",
    "Ihavedesire4#879304",
    "Ihavedesire4#320163",
    "Ihavedesire4#724840",
    "Ihavedesire4#114950",
    "Ihavedesire4#963156",
    "Ihavedesire4#562133",
    "Ihavedesire4#393499",
    "Ihavedesire4#103001",
    "Ihavedesire4#356621",
    "Ihavedesire4#796264",
    "Ihavedesire4#399490",
    "Ihavedesire4#362007",
    "Ihavedesire4#400842",
    "Ihavedesire4#843081",
    "Ihavedesire4#914753",
    "Ihavedesire4#587271",
    "Ihavedesire4#240056",
    "Ihavedesire4#412113",
    "Ihavedesire4#123133",
    "Ihavedesire4#105419",
  ];

  await setDoc(AkashaPassDocRef, { AkashaPass: Akashapass });
}

export async function SetAkashaPassFunctionUsageUnique() {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const AkashaPassSnapshot = await getDoc(AkashaPassDocRef);
  const allAkashaPass = AkashaPassSnapshot.data().AkashaPass;

  const newAllAkashaPass = allAkashaPass.map((pass) => ({
    pass,
    validity: true,
  }));
  await setDoc(AkashaPassDocRef, { AkashaPass: newAllAkashaPass });
}

export async function AddNewAkashaPAss(Akashapass) {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const AkashaPassSnapshot = await getDoc(AkashaPassDocRef);
  const allAkashaPass = AkashaPassSnapshot.data().AkashaPass;

  const newPass = [`Ihavedesire4#${Akashapass.PassName}`];
  const newPassvalid = newPass.map((pass) => ({ pass, validity: true }));
  const newAllList = [...allAkashaPass, ...newPassvalid];

  await setDoc(AkashaPassDocRef, { AkashaPass: newAllList });
}

export async function CreateNewSanctuaryBooking(
  userID,
  date,
  time,
  ListOfGuestMember
) {
  const documentId = date;
  const docRef = doc(firestore, "SanctuaryBooking", documentId);

  try {
    // Vérifie si le document existe déjà
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let docData = docSnap.data();
      // Si le document existe, mettez-le à jour
      await setDoc(
        docRef,
        {
          // Ajoutez les champs que vous souhaitez mettre à jour
          BookingList: [
            ...docData.BookingList,
            { userID, time, ListOfGuestMember },
          ],
        },
        { merge: true }
      ); // merge:true pour ne pas écraser les données existantes
    } else {
      // Si le document n'existe pas, créez-le
      await setDoc(docRef, {
        BookingList: [{ userID, time, ListOfGuestMember }],
      });
    }

    const docRefUser = doc(firestore, "users", userID);
    const docSnapUser = await getDoc(docRefUser);
    if (docSnapUser.exists()) {
      await setDoc(
        docRefUser,
        {
          // Ajoutez les champs que vous souhaitez mettre à jour
          sanctuaryBookingList: arrayUnion(date),
        },
        { merge: true }
      );
    } else {
      throw "L'utilisateur n'existe pas";
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la mise à jour du document:",
      error
    );
  }
}
export async function SetListWelnessEvent() {
  const docRef = doc(firestore, "Akasha", "WelnessEventPlanning");

  try {
    // Vérifie si le document existe déjà
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await setDoc(
        docRef,
        {
          // Ajoutez les champs que vous souhaitez mettre à jour
          ListEvents: [
            {
              ID: uuidv4(),
              ListeParticipant: [],
              Day: "2024-10-30",
              Type: "Yoga",
              Hour: "MORNING",
              Duration: "08:00 - 09:00",
              title: "Atelier de Peinture",
              FacilitatorName: "Facilitator name",
              Description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            },
            {
              ID: uuidv4(),
              ListeParticipant: [],
              Day: "2024-10-30",
              Type: "Yoga",
              Hour: "MORNING",
              Duration: "08:00 - 09:00",
              title: "Atelier de Peinture",
              FacilitatorName: "Facilitator name",
              Description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            },
            {
              ListeParticipant: [],
              ID: uuidv4(),
              Day: "2024-10-30",
              Type: "Yoga",
              Hour: "MORNING",
              Duration: "08:00 - 09:00",
              title: "Atelier de Peinture",
              FacilitatorName: "Facilitator name",
              Description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            },
          ],
          TypeEvent: [{ Name: "Yoga", ID: uuidv4() }],
        },
        { merge: true }
      ); // merge:true pour ne pas écraser les données existantes
    } else {
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la mise à jour du document:",
      error
    );
  }
}
export async function GetListWelnessEventForTheWeek(Week) {
  const docRef = doc(firestore, "Akasha", "WelnessEventPlanning");

  try {
    // Vérifie si le document existe
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Le document existe, récupère et retourne les données pour la semaine
      const WelnessPlanning = docSnap.data().ListEvents;

      let ListEventIntheWeek = [];

      Week.forEach((dayOfWeek) => {
        WelnessPlanning.forEach((event) => {
          let convertDateEvent = new Date(event.Day);

          // Utilise `toDateString()` pour comparer les dates au jour près
          if (convertDateEvent.toDateString() === dayOfWeek.toDateString()) {
            ListEventIntheWeek.push({
              ...event,
              Date: event.Day,
              Day: convertDateEvent.toLocaleDateString("en-US", {
                weekday: "long",
              }),
            });
          }
        });
      });

      console.log(ListEventIntheWeek);
      return ListEventIntheWeek;
    } else {
      console.log("Le document n'existe pas dans la base de données.");
      // Ici, vous pourriez ajouter la logique pour créer un nouveau document si nécessaire
      return []; // Retourne un tableau vide si le document n'existe pas
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la récupération du document:",
      error
    );
    throw error; // Relance l'erreur pour qu'elle soit gérée ailleurs dans le code
  }
}
export async function HandleBookAClass(userID, ClassID) {
  const docRef = doc(firestore, "Akasha", "WelnessEventPlanning");

  try {
    // Vérifiez si le document existe déjà
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docData = docSnap.data();

      // Récupérez et mettez à jour l'événement correspondant à ClassID
      let updatedListEvents = docData.ListEvents.map((event) =>
        event.ID === ClassID
          ? { ...event, ListeParticipant: [...event.ListeParticipant, userID] } // Ajouter/mettre à jour le champ bookedBy avec userID
          : event
      );

      // Mettez à jour ListEvents dans Firestore
      await setDoc(
        docRef,
        {
          ListEvents: updatedListEvents,
        },
        { merge: true }
      );
    } else {
      console.log("Document n'existe pas !");
    }
    const docRefUser = doc(firestore, "users", userID);
    const docSnapUser = await getDoc(docRefUser);

    if (docSnapUser.exists()) {
      const UserData = docSnapUser.data();

      await setDoc(
        docRefUser,
        {
          WelnessEventBookingList: arrayUnion(ClassID),
        },
        { merge: true }
      );
    } else {
      console.log("Document n'existe pas !");
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la mise à jour du document:",
      error
    );
  }
}
export async function HandleCreateNewClass(NewClassData) {
  const docRef = doc(firestore, "Akasha", "WelnessEventPlanning");

  try {
    // Vérifiez si le document existe déjà
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docData = docSnap.data();

      // Récupérez et mettez à jour l'événement correspondant à ClassID

      // Mettez à jour ListEvents dans Firestore
      await setDoc(
        docRef,
        {
          ListEvents: arrayUnion({
            ...NewClassData,
            ID: uuidv4(),
            ListeParticipant: [],
          }),
        },
        { merge: true }
      );
    } else {
      console.log("Document n'existe pas !");
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la mise à jour du document:",
      error
    );
  }
}

export async function HandleCreateNewSocialEvent(NewSocialEventData) {
  const docRef = doc(firestore, "Akasha", "SocialEventPlanning");

  try {
    // Vérifiez si le document existe déjà
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docData = docSnap.data();

      // Récupérez et mettez à jour l'événement correspondant à ClassID

      // Mettez à jour ListEvents dans Firestore
      await setDoc(
        docRef,
        {
          ListEvents: arrayUnion({
            ...NewSocialEventData,
            ID: uuidv4(),
            ListeParticipant: [],
          }),
        },
        { merge: true }
      );
    } else {
      console.log("Document n'existe pas !");
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la mise à jour du document:",
      error
    );
  }
}

export async function GetUserBookingData(UserID) {
  const docRef = doc(firestore, "users", UserID);
  const docRefconfirmedBookingByAdmin = doc(
    firestore,
    "Akasha",
    "BookingValidationByAdmin"
  );
  const docSnapconfirmedBookingByAdmin = await getDoc(
    docRefconfirmedBookingByAdmin
  );
  const SanctuaryBookingConfirmation =
    docSnapconfirmedBookingByAdmin.data().SanctuaryBookingConfirmation;
  const SocialEventConfirmation =
    docSnapconfirmedBookingByAdmin.data().SocialEventConfirmation;

  const WelnessEventConfirmation =
    docSnapconfirmedBookingByAdmin.data().WelnessEventConfirmation;

  try {
    // Vérifiez si le document existe déjà
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docData = docSnap.data();
      let sanctuaryBookingList = [];
      let WelnessEventBookingList = [];
      let SocialEventBookingList = [];

      if (docData.hasOwnProperty("sanctuaryBookingList")) {
        for (const sanctuaryBookingID of docData.sanctuaryBookingList) {
          const docRefsanctuaryBooking = doc(
            firestore,
            "SanctuaryBooking",
            sanctuaryBookingID
          );
          const docSnapSanctuaryBooking = await getDoc(docRefsanctuaryBooking);
          if (docSnapSanctuaryBooking.exists()) {
            const booking = docSnapSanctuaryBooking
              .data()
              .BookingList.find((booking) => booking.userID === UserID);

            if (booking) {
              // Is booking Confirmed By admin ?
              let isConfirmedByAdmin = SanctuaryBookingConfirmation.find(
                (bookingConfirmation) =>
                  bookingConfirmation.userID === UserID &&
                  bookingConfirmation.State === "Approved"
              );

              let isCancelByAdmin = SanctuaryBookingConfirmation.find(
                (bookingConfirmation) =>
                  bookingConfirmation.userID === UserID &&
                  bookingConfirmation.State === "Rejected"
              );

              isConfirmedByAdmin &&
                sanctuaryBookingList.push({
                  ...booking,
                  Date: sanctuaryBookingID,
                  Status: "Approved",
                });

              isCancelByAdmin &&
                sanctuaryBookingList.push({
                  ...booking,
                  Date: sanctuaryBookingID,
                  Status: "Rejected",
                });

              !isConfirmedByAdmin &&
                !isCancelByAdmin &&
                sanctuaryBookingList.push({
                  ...booking,
                  Date: sanctuaryBookingID,
                  Status: "Pending",
                });
            } else {
              console.log("Aucun booking trouvé pour cet utilisateur.");
            }
          } else {
            throw "Le sanctuary booking n'existe pas";
          }
        }
      }

      if (docData.hasOwnProperty("WelnessEventBookingList")) {
        for (const WelnessEventBookingID of docData.WelnessEventBookingList) {
          const docRefWelnessEventID = doc(
            firestore,
            "Akasha",
            "WelnessEventPlanning"
          );
          const docSnapWelnessEventPlanning = await getDoc(
            docRefWelnessEventID
          );
          if (docSnapWelnessEventPlanning.exists()) {
            const booking = docSnapWelnessEventPlanning
              .data()
              .ListEvents.find(
                (booking) => booking.ID === WelnessEventBookingID
              );

            if (booking) {
              // Is booking Confirmed By admin ?
              let isConfirmedByAdmin = WelnessEventConfirmation.find(
                (bookingConfirmation) =>
                  bookingConfirmation.userID === UserID &&
                  bookingConfirmation.IDWelnessEvent ===
                    WelnessEventBookingID &&
                  bookingConfirmation.State === "Approved"
              );

              let isCancelByAdmin = WelnessEventConfirmation.find(
                (bookingConfirmation) =>
                  bookingConfirmation.userID === UserID &&
                  bookingConfirmation.IDWelnessEvent ===
                    WelnessEventBookingID &&
                  bookingConfirmation.State === "Rejected"
              );

              isConfirmedByAdmin &&
                WelnessEventBookingList.push({
                  ...booking,
                  Status: "Approved",
                });

              isCancelByAdmin &&
                WelnessEventBookingList.push({
                  ...booking,
                  Status: "Rejected",
                });

              !isConfirmedByAdmin &&
                !isCancelByAdmin &&
                WelnessEventBookingList.push({
                  ...booking,
                  Status: "Pending",
                });
            } else {
              console.log("Aucun booking trouvé pour cet utilisateur.");
            }
          } else {
            throw "error docSnapWelnessEventPlanning n'existe pas";
          }
        }
      }

      if (docData.hasOwnProperty("SocialEventBookingList")) {
        for (const SocialEventBookingID of docData.SocialEventBookingList) {
          const docRefSocialEvent = doc(
            firestore,
            "Akasha",
            "SocialEventPlanning"
          );
          const docSnapSocialEventPlanning = await getDoc(docRefSocialEvent);
          if (docSnapSocialEventPlanning.exists()) {
            const booking = docSnapSocialEventPlanning
              .data()
              .ListEvents.find(
                (booking) => booking.ID === SocialEventBookingID
              );

            if (booking) {
              let isConfirmedByAdmin = SocialEventConfirmation.find(
                (bookingConfirmation) =>
                  bookingConfirmation.userID === UserID &&
                  bookingConfirmation.IDSocialEvent === SocialEventBookingID &&
                  bookingConfirmation.State === "Approved"
              );

              let isCancelByAdmin = SocialEventConfirmation.find(
                (bookingConfirmation) =>
                  bookingConfirmation.userID === UserID &&
                  bookingConfirmation.IDSocialEvent === SocialEventBookingID &&
                  bookingConfirmation.State === "Rejected"
              );

              isConfirmedByAdmin &&
                SocialEventBookingList.push({
                  ...booking,
                  Status: "Approved",
                });

              isCancelByAdmin &&
                SocialEventBookingList.push({
                  ...booking,
                  Status: "Rejected",
                });

              !isConfirmedByAdmin &&
                !isCancelByAdmin &&
                SocialEventBookingList.push({
                  ...booking,
                  Status: "Pending",
                });
            } else {
              console.log("Aucun booking trouvé pour cet utilisateur.");
            }
          } else {
            throw "error docSnapWelnessEventPlanning n'existe pas";
          }
        }
      }

      let StructuredArray = [];
      sanctuaryBookingList.forEach((sanctuaryBooking) => {
        return sanctuaryBooking.ListOfGuestMember.length === 1 &&
          sanctuaryBooking.ListOfGuestMember[0] === ""
          ? StructuredArray.push({
              type: "Sanctuary Booking",
              eventName: "Table",
              Pax: "1",
              Date: sanctuaryBooking.Date,
              Time: sanctuaryBooking.time,
              Status: sanctuaryBooking.Status,
              Action: "dggdg",
              ID: sanctuaryBooking.Date,
              UserID: docData.uid,
            })
          : StructuredArray.push({
              type: "Sanctuary Booking",
              eventName: "Table",
              Pax: sanctuaryBooking.ListOfGuestMember.length + 1,
              Date: sanctuaryBooking.Date,
              Time: sanctuaryBooking.time,
              Status: sanctuaryBooking.Status,
              Action: "dggdg",
              ID: sanctuaryBooking.Date,
              UserID: docData.uid,
            });
      });

      WelnessEventBookingList.forEach((WellnessBooking) =>
        StructuredArray.push({
          type: "Wellness Event",
          eventName: WellnessBooking.title,
          Pax: "1",
          Date: WellnessBooking.Day,
          Time: WellnessBooking.Duration,
          Status: WellnessBooking.Status,
          Action: "dggdg",
          ID: WellnessBooking.ID,
          UserID: docData.uid,
        })
      );

      SocialEventBookingList.forEach((SocialEventBooking) =>
        StructuredArray.push({
          type: "Social Event",
          eventName: SocialEventBooking.title,
          Pax: "1",
          Date: SocialEventBooking.Day,
          Time: SocialEventBooking.Duration,
          Status: SocialEventBooking.Status,
          Action: "dggdg",
          ID: SocialEventBooking.ID,
          UserID: docData.uid,
        })
      );

      console.log({
        sanctuaryBookingList,
        WelnessEventBookingList,
        SocialEventBookingList,
        UserData: docData,
      });
      return StructuredArray;
    } else {
      console.log("Document n'existe pas !");
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la mise à jour du document:",
      error
    );
  }
}
export async function SetBookingValidationByadmin() {
  try {
    const bookingDocRef = doc(firestore, "Akasha", "BookingValidationByAdmin");

    // Mettre à jour le document de réservation
    await updateDoc(bookingDocRef, {
      SocialEventConfirmation: [
        {
          IDSocialEvent: "93680368-5534-4f9f-a131-9b31c4efc5e6",
          userID: "2xt2gQrxpYg4kTxcNj5rMRTK8wI2",
          State: "Approved",
        },
      ],
      WelnessEventConfirmation: [
        {
          IDWelnessEvent: "ea072ada-9b12-4d28-8f7d-7230ebfe94ec",
          userID: "2xt2gQrxpYg4kTxcNj5rMRTK8wI2",
          State: "Approved",
        },
      ],
      SanctuaryBookingConfirmation: [
        {
          IDSanctuaryBooking: "2024-11-08",
          userID: "UWfViQ1mdlZjzs2O83MSYxLtDoL2",
          State: "Cancel",
        },
      ],
    });
  } catch (error) {
    console.error("Error validating booking by admin:", error);
  }
}

export async function HandleCancelBooking(CalssData) {
  console.log(CalssData);
  const docRef = doc(firestore, "users", CalssData.UserID);

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const docData = docSnap.data();
      if (docData.hasOwnProperty("sanctuaryBookingList")) {
        let BookingIsATable = docData.sanctuaryBookingList.find(
          (booking) => booking === CalssData.ID
        );

        if (BookingIsATable) {
          const docRefSanctuaryBooking = doc(
            firestore,
            "SanctuaryBooking",
            BookingIsATable
          );
          const docSnapSanctuaryBooking = await getDoc(docRefSanctuaryBooking);
          if (docSnapSanctuaryBooking.exists()) {
            const bookingList = docSnapSanctuaryBooking.data().BookingList;
            const bookingToRemove = bookingList.find(
              (booking) => booking.userID === CalssData.UserID
            );

            await updateDoc(docRefSanctuaryBooking, {
              BookingList: arrayRemove(bookingToRemove),
            });

            await updateDoc(docRef, {
              sanctuaryBookingList: arrayRemove(BookingIsATable),
            });
          }
        }
      }

      if (docData.hasOwnProperty("WelnessEventBookingList")) {
        let BookingIsAWellnessEvent = docData.WelnessEventBookingList.find(
          (booking) => booking === CalssData.ID
        );

        if (BookingIsAWellnessEvent) {
          const docRefWelnessEventPlanning = doc(
            firestore,
            "Akasha",
            "WelnessEventPlanning"
          );
          const docSnapWelnessEventPlanning = await getDoc(
            docRefWelnessEventPlanning
          );
          if (docSnapWelnessEventPlanning.exists()) {
            const bookingList = docSnapWelnessEventPlanning.data().ListEvents;

            const NewBookingList = bookingList.map((Event) => {
              if (Event.ID === CalssData.ID) {
                if (
                  Event.ListeParticipant.find(
                    (participant) => participant === CalssData.UserID
                  )
                ) {
                  const NewListParticipant = Event.ListeParticipant.filter(
                    (participant) => participant !== CalssData.UserID
                  );
                  return { ...Event, ListeParticipant: NewListParticipant };
                }
                // Si l'utilisateur n'est pas trouvé, retourner l'événement tel quel
                return Event;
              } else {
                // Si l'événement n'est pas celui que nous cherchons, retourner l'événement tel quel
                return Event;
              }
            });

            // Mettre à jour le document avec la nouvelle liste d'événements
            await updateDoc(docSnapWelnessEventPlanning.ref, {
              ListEvents: NewBookingList,
            });

            await updateDoc(docRef, {
              WelnessEventBookingList: arrayRemove(BookingIsAWellnessEvent),
            });
          }
        }
      }

      if (docData.hasOwnProperty("SocialEventBookingList")) {
        let BookingIsASocialEvent = docData.SocialEventBookingList.find(
          (booking) => booking === CalssData.ID
        );

        if (BookingIsASocialEvent) {
          const docRefSocialEventPlanning = doc(
            firestore,
            "Akasha",
            "SocialEventPlanning"
          );
          const docSnapSocialEventPlanning = await getDoc(
            docRefSocialEventPlanning
          );
          if (docSnapSocialEventPlanning.exists()) {
            const bookingList = docSnapSocialEventPlanning.data().ListEvents;

            const NewBookingList = bookingList.map((Event) => {
              if (Event.ID === CalssData.ID) {
                if (
                  Event.ListeParticipant.find(
                    (participant) => participant === CalssData.UserID
                  )
                ) {
                  const NewListParticipant = Event.ListeParticipant.filter(
                    (participant) => participant !== CalssData.UserID
                  );
                  return { ...Event, ListeParticipant: NewListParticipant };
                }
                // Si l'utilisateur n'est pas trouvé, retourner l'événement tel quel
                return Event;
              } else {
                // Si l'événement n'est pas celui que nous cherchons, retourner l'événement tel quel
                return Event;
              }
            });

            // Mettre à jour le document avec la nouvelle liste d'événements
            await updateDoc(docSnapSocialEventPlanning.ref, {
              ListEvents: NewBookingList,
            });

            await updateDoc(docRef, {
              SocialEventBookingList: arrayRemove(BookingIsASocialEvent),
            });
          }
        }
      }
    }
  } catch {}
}
export async function SetListSocialEvent() {
  const docRef = doc(firestore, "Akasha", "SocialEventPlanning");

  try {
    // Vérifie si le document existe déjà
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await setDoc(
        docRef,
        {
          // Ajoutez les champs que vous souhaitez mettre à jour
          ListEvents: [
            {
              ID: uuidv4(),
              ListeParticipant: [],
              Day: "2024-10-30",
              Type: "Yoga",
              Hour: "MORNING",
              Duration: "08:00 - 09:00",
              title: "Atelier de Peinture",
              FacilitatorName: "Facilitator name",
              Description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            },
            {
              ID: uuidv4(),
              ListeParticipant: [],
              Day: "2024-10-30",
              Type: "Yoga",
              Hour: "MORNING",
              Duration: "08:00 - 09:00",
              title: "Atelier de Peinture",
              FacilitatorName: "Facilitator name",
              Description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            },
            {
              ListeParticipant: [],
              ID: uuidv4(),
              Day: "2024-10-30",
              Type: "Yoga",
              Hour: "MORNING",
              Duration: "08:00 - 09:00",
              title: "Atelier de Peinture",
              FacilitatorName: "Facilitator name",
              Description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            },
          ],
          TypeEvent: [{ Name: "Yoga", ID: uuidv4() }],
        },
        { merge: true }
      ); // merge:true pour ne pas écraser les données existantes
    } else {
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la mise à jour du document:",
      error
    );
  }
}
export async function GetListSocialEventForTheWeek(Week) {
  const docRef = doc(firestore, "Akasha", "SocialEventPlanning");

  try {
    // Vérifie si le document existe
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Le document existe, récupère et retourne les données pour la semaine
      const SocialEventPlanning = docSnap.data().ListEvents;

      let ListEventIntheWeek = [];

      Week.forEach((dayOfWeek) => {
        SocialEventPlanning.forEach((event) => {
          let convertDateEvent = new Date(event.Day);

          // Utilise `toDateString()` pour comparer les dates au jour près
          if (convertDateEvent.toDateString() === dayOfWeek.toDateString()) {
            ListEventIntheWeek.push({
              ...event,
              Date: event.Day,
              Day: convertDateEvent.toLocaleDateString("en-US", {
                weekday: "long",
              }),
            });
          }
        });
      });

      return ListEventIntheWeek;
    } else {
      console.log("Le document n'existe pas dans la base de données.");
      // Ici, vous pourriez ajouter la logique pour créer un nouveau document si nécessaire
      return []; // Retourne un tableau vide si le document n'existe pas
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la récupération du document:",
      error
    );
    throw error; // Relance l'erreur pour qu'elle soit gérée ailleurs dans le code
  }
}
export async function HandleBookSocialevent(userID, SocialEventID) {
  const docRef = doc(firestore, "Akasha", "SocialEventPlanning");

  try {
    // Vérifiez si le document existe déjà
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docData = docSnap.data();

      let updatedListEvents = docData.ListEvents.map((event) =>
        event.ID === SocialEventID
          ? { ...event, ListeParticipant: [...event.ListeParticipant, userID] } // Ajouter/mettre à jour le champ bookedBy avec userID
          : event
      );

      // Mettez à jour ListEvents dans Firestore
      await setDoc(
        docRef,
        {
          ListEvents: updatedListEvents,
        },
        { merge: true }
      );
    } else {
      console.log("Document n'existe pas !");
    }
    const docRefUser = doc(firestore, "users", userID);
    const docSnapUser = await getDoc(docRefUser);

    if (docSnapUser.exists()) {
      const UserData = docSnapUser.data();

      await setDoc(
        docRefUser,
        {
          SocialEventBookingList: arrayUnion(SocialEventID),
        },
        { merge: true }
      );
    } else {
      console.log("Document n'existe pas !");
    }
  } catch (error) {
    console.error(
      "Erreur lors de la vérification ou de la mise à jour du document:",
      error
    );
  }
}

export async function UpdateAllMemberToTraveler() {
  try {
    // Récupération de tous les documents de la collection 'users'
    const usersCollectionRef = collection(firestore, "users");
    const querySnapshot = await getDocs(usersCollectionRef);

    // Mise à jour de chaque document pour définir la subscription à 'TRAVELER'
    const updatePromises = querySnapshot.docs.map((docf) => {
      const userDocRef = doc(firestore, "users", docf.id);
      return updateDoc(userDocRef, {
        subscription: "TRAVELER",
      });
    });

    // Attente de la fin de toutes les mises à jour
    await Promise.all(updatePromises);
    console.log("All members updated to TRAVELER subscription");
    return true; // Retourne true si la mise à jour est réussie
  } catch (e) {
    console.error("Error updating documents: ", e);
    return false; // Gestion d'erreur, retourne false en cas d'échec
  }
}

export async function getUserData(userID) {
  try {
    const userDocRef = doc(firestore, "users", userID);
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      console.log("User data:", docSnapshot.data());
      return docSnapshot.data(); // Retourne les données de l'utilisateur si le document existe
    } else {
      console.log("No user data available");
      return null; // Retourne null si aucun document n'est trouvé
    }
  } catch (e) {
    console.error("Error retrieving user data: ", e);
    return false; // Gestion d'erreur, retourne false en cas d'échec
  }
}

export async function getBookingForTheWeek() {
  try {
    const AllBookingDocRef = doc(firestore, "Akasha", "SanctuaryBooking");
    const AllBookingSnapshot = await getDoc(AllBookingDocRef);

    if (AllBookingSnapshot.exists()) {
      console.log(
        "AllBookingSnapshot:",
        AllBookingSnapshot.data().SanctuaryBooking
      );

      let aujourdhui = new Date();
      let uneSemainePlusTard = new Date(
        aujourdhui.getTime() + 7 * 24 * 60 * 60 * 1000
      );
      let allFuturBooking = [];
      for (const BookingID of AllBookingSnapshot.data().SanctuaryBooking) {
        const BookingDocRef = doc(firestore, "SanctuaryBooking", BookingID);
        const BookingSnapshot = await getDoc(BookingDocRef);
        if (BookingSnapshot.exists()) {
          let FormatedBookingTime = new Date(
            BookingSnapshot.data().BookingDate.seconds * 1000
          );

          if (FormatedBookingTime.getTime() > aujourdhui.getTime()) {
            // on recupere les data user
            const userDocRef = doc(
              firestore,
              "users",
              BookingSnapshot.data().userID
            );
            const docSnapshot = await getDoc(userDocRef);
            if (docSnapshot.exists()) {
              allFuturBooking.push({
                ...BookingSnapshot.data(),
                userData: docSnapshot.data(),
              });
            } else {
              throw "Le user n'existe pas dans la base user firebase";
            }
          }
        } else {
          console.log("Le booking n'existe pas");
        }
      }
      // on trie par rapport a la reservation la plus proche d'aujourd'hui
      allFuturBooking.sort((a, b) => {
        const dateA = new Date(a.BookingDate.seconds * 1000);
        const dateB = new Date(b.BookingDate.seconds * 1000);
        return dateA - dateB; // Tri croissant
      });

      console.log(allFuturBooking);
      return allFuturBooking; // Retourne les données de l'utilisateur si le document existe
    } else {
      console.log("Nodocs");
      return null; // Retourne null si aucun document n'est trouvé
    }
  } catch (e) {
    console.error("Error retrieving user data: ", e);
    return false; // Gestion d'erreur, retourne false en cas d'échec
  }
}
