import React, { useState, useEffect } from "react";
import "./BookASocialEventComputerMode.css";
import { GetListSocialEventForTheWeek } from "../../../Logic/HandleClickFunctions";
import ModalBookSocialEvent from "../ModalBookSocialEvent/ModalBookSocialEvent";

// Composant pour le planning des ateliers
const BookASocialEventComputerMode = ({ UserID }) => {
  // État pour suivre la semaine actuelle affichée
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek());
  const [workshops, setWorkshops] = useState([]);
  const [activitiesType, setActivitiesType] = useState([
    "Yoga",
    "Breathwork",
    "Sound Bath",
    "Pilates",
    "Pilates",
    "Pilates",
  ]);

  // Générer la semaine actuelle (du lundi au dimanche)
  function getCurrentWeek() {
    const current = new Date();
    const firstDayOfWeek = current.getDate() - current.getDay() + 1;
    const startOfWeek = new Date(current.setDate(firstDayOfWeek));
    const week = [];

    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      week.push(day);
    }
    return week;
  }

  // Aller à la semaine précédente
  const previousWeek = () => {
    const newWeek = currentWeek.map((day) => {
      const newDay = new Date(day);
      newDay.setDate(day.getDate() - 7);
      return newDay;
    });
    setCurrentWeek(newWeek);
  };

  // Aller à la semaine suivante
  const nextWeek = () => {
    const newWeek = currentWeek.map((day) => {
      const newDay = new Date(day);
      newDay.setDate(day.getDate() + 7);
      return newDay;
    });
    setCurrentWeek(newWeek);
  };

  // Charger les ateliers (simulé ici avec un useEffect)

  useEffect(() => {
    const fetchWorkshops = async () => {
      // Appel asynchrone pour obtenir les ateliers
      const sampleWorkshops2 = [
        {
          day: "lundi",
          hour: "MORNING",
          duration: "08:00 - 09:00",
          title: "Atelier de Peinture",
          FacilitatorName: "Facilitator name",
        },
        {
          day: "lundi",
          hour: "MORNING",
          duration: "08:00 - 09:00",
          title: "Atelier de Peinture",
          FacilitatorName: "Facilitator name",
        },
        {
          day: "mercredi",
          hour: "AFTERNOON",
          duration: "08:00 - 09:00",
          title: "Cours de Cuisine",
          FacilitatorName: "Facilitator name",
        },
        {
          day: "vendredi",
          hour: "AFTERNOON",
          duration: "08:00 - 09:00",
          title: "Atelier de Programmation",
          FacilitatorName: "Facilitator name",
        },
        {
          day: "vendredi",
          hour: "EVENING",
          duration: "08:00 - 09:00",
          title: "Atelier de Programmation",
          FacilitatorName: "Facilitator name",
        },
      ];
      const sampleWorkshops = await GetListSocialEventForTheWeek(currentWeek);
      console.log(sampleWorkshops);
      setWorkshops(sampleWorkshops);
    };

    fetchWorkshops();
  }, [currentWeek]);

  // Obtenir les jours de la semaine sous forme de noms
  const getDayName = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  // Crée une grille de 7 colonnes et des lignes horaires
  const renderGrid = () => {
    const hours = ["MORNING", "AFTERNOON", "EVENING"];

    return hours.map((hour) => (
      <div key={hour} className="row">
        {/* Afficher l'heure dans la première colonne */}
        <div className="cell hour-cell">{hour}</div>
        {/* Afficher les cellules des jours */}
        {currentWeek.map((day) => (
          <div key={day} className="cell">
            {workshops
              .filter(
                (workshop) =>
                  getDayName(day) === workshop.Day && workshop.Hour === hour
              )
              .map((workshop, index) => (
                <div key={index} className="workshopBookSocialEvent">
                  <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                    {workshop.title}
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    {workshop.Duration}
                  </div>
                  <div
                    style={{
                      paddingBottom: "10px",
                      borderBottom: "solid 1px #D9D9D9",
                      marginBottom: "10px",
                    }}
                  >
                    {workshop.FacilitatorName}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingRight: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    <div></div>
                    <div>
                      <ModalBookSocialEvent
                        CalssData={workshop}
                        UserID={UserID}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <>
      <div></div>
      <div className="ContenerBookAsocialEvent">
        <h2 style={{ color: "#603D88" }}>Book a social event</h2>
        {/* Boutons de navigation */}
        <div className="navigation">
          <div className="navigationdiv">
            <div onClick={previousWeek} style={{ cursor: "pointer" }}>
              {"<"}
            </div>
            <div>
              Mon. {currentWeek[0].getDate()}/{currentWeek[0].getMonth() + 1} -
              Sun. {currentWeek[6].getDate()}/{currentWeek[6].getMonth() + 1}
            </div>
            <div onClick={nextWeek} style={{ cursor: "pointer" }}>
              {">"}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              padding: "15px",
              paddingLeft: "50px",
              paddingRight: "50px",
              borderRadius: "5px",
              border: "solid 1px #C8C2B8",
              marginRight: "15px",
              color: "white",
              backgroundColor: "#603D88",
            }}
          >
            See All
          </div>
          {/*activitiesType.map((activity) => (
            <div className="ActivityTypeSelectionBookSocialEvent">
              {activity}
            </div>
          ))*/}
        </div>

        {/* Grille de la semaine */}
        <div className="grid">
          <div className="row header">
            <div className="cell hour-header">Heure</div>
            {currentWeek.map((day, index) => (
              <div key={index} className="cell">
                {getDayName(day)} <br /> {day.toLocaleDateString("fr-FR")}
              </div>
            ))}
          </div>
          {renderGrid()}
        </div>
      </div>
    </>
  );
};

export default BookASocialEventComputerMode;
