import React, { useState, useEffect } from "react";
import "./BookAClass.css";
import BookAClassMobileMode from "./BookAClassMobileMode/BookAClassMobileMode";
import BookAClassComputerMode from "./BookAClassComputerMode/BookAClassComputerMode";

// Composant pour le planning des ateliers
const BookAClass = ({ UserID }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Fonction pour mettre à jour la largeur de la fenêtre
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Ajouter l'écouteur d'événements
    window.addEventListener("resize", handleResize);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {windowWidth >= 1100 ? (
        <BookAClassComputerMode UserID={UserID} />
      ) : (
        <BookAClassMobileMode UserID={UserID} />
      )}
    </>
  );
};

export default BookAClass;
