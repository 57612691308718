import React, { useState, useEffect } from "react";
import { getUserData } from "../../../Logic/HandleClickFunctions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import "./UserDetail.css";

const UserDetail = () => {
  const [userData, setUserData] = useState(null);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    async function fetchData() {
      const userData = await getUserData(user.uid);
      setUserData(userData);
    }
    user && fetchData();
  }, []);

  return (
    <div className="UserDetailContener">
      {" "}
      <div className="GeneralInformations">
        <div className="TextGeneralInformations">General Informations</div>
        {userData && (
          <>
            <input
              type="prenom"
              name="prenom"
              placeholder="prenom"
              value={userData.userData.FirstName}
              style={{
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                height: "30px",
              }}
            />
            <input
              type="nom"
              name="nom"
              placeholder="nom"
              value={userData.userData.LastName}
              style={{
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                height: "30px",
              }}
            />
            <input
              type="tel"
              name="tel"
              placeholder="tem"
              value={userData.userData.PhoneNumber}
              style={{
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                height: "30px",
              }}
            />
            <input
              type="mail"
              name="mail"
              placeholder="mail"
              value={userData.userData.Email}
              style={{
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                height: "30px",
              }}
            />
            <input
              type="genre"
              name="genre"
              placeholder="genre"
              value={userData.userData.Genre}
              style={{
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                height: "30px",
              }}
            />
          </>
        )}
      </div>
      <div className="AccountInformations">
        <div className="TextGeneralInformations">Akasha Profile</div>
        {userData && (
          <input
            type="pseudo"
            name="pseudo"
            placeholder="pseudo"
            value={userData.userData.MemberPseudo}
            style={{
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginBottom: "10px",
              height: "30px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UserDetail;
