import "./OnboardingAppV2.css";
import React, { useState, useEffect } from "react";
import { HandleUpdateUserData } from "../../Logic/HandleClickFunctions";

function OnboardingAppV2({ userId }) {
  const [step, setStep] = useState(1);
  const [userInfo, setUserInfo] = useState({
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    Genre: "",
    MemberPseudo: "",
    DateOfBirth: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await HandleUpdateUserData(userId, userInfo);
      setStep(2);
      // Logique pour soumettre le formulaire ou créer un compte
      console.log(userInfo);
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  return (
    <>
      <div></div>
      <div className="ContenerFirstConnectionProcess">
        {" "}
        {step == 1 && (
          <div>
            <div
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                marginTop: "50px",
                marginBottom: "30px",
                color: "#011D9B",
              }}
            >
              Update Your Account
            </div>
            <div style={{ marginLeft: "30px" }}>
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    color: "#011D9B",
                  }}
                >
                  General Informations
                </div>
                <input
                  type="text"
                  name="FirstName"
                  placeholder="First Name*"
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    width: "80%",
                    marginBottom: "10px",
                  }}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      FirstName: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  name="LastName"
                  placeholder="Last Name*"
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    width: "80%",
                    marginBottom: "10px",
                  }}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      LastName: e.target.value,
                    }))
                  }
                />
                <input
                  type="tel"
                  name="PhoneNumber"
                  placeholder="Phone Number*"
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    width: "80%",
                    marginBottom: "10px",
                  }}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      PhoneNumber: e.target.value,
                    }))
                  }
                />

                <select
                  name="Genre"
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    width: "80%",
                    marginBottom: "10px",
                  }}
                  onChange={(e) =>
                    setUserInfo((prev) => ({ ...prev, Genre: e.target.value }))
                  }
                >
                  <option value="">Select Gender*</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Non-binary">Non-binary</option>
                  <option value="Transgender Man">Transgender Man</option>
                  <option value="Transgender Woman">Transgender Woman</option>
                  <option value="Queer">Queer</option>
                  <option value="Other">Other</option>
                </select>

                <label
                  htmlFor="DateOfBirth"
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    color: "gray",
                    fontSize: "12px",
                    paddingLeft: "5px",
                  }}
                >
                  Date of Birth*
                </label>
                <input
                  type="date"
                  name="DateOfBirth"
                  id="DateOfBirth"
                  placeholder="Date of Birth*"
                  required
                  title="Please enter your date of birth"
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    width: "80%",
                    marginBottom: "10px",
                  }}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      DateOfBirth: e.target.value,
                    }))
                  }
                />

                <div
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    color: "#011D9B",
                  }}
                >
                  Akasha Profile
                </div>
                <input
                  type="text"
                  name="MemberPseudo"
                  placeholder="Akasha Username*"
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    width: "80%",
                    marginBottom: "10px",
                  }}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      MemberPseudo: e.target.value,
                    }))
                  }
                />
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    color: "#011D9B",
                    width: "80%",
                    marginBottom: "10px",
                  }}
                >
                  Your username will be your unique identifier within the Akasha
                  community. You can share it with other members to register for
                  events or book tables as a group.
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "15px",
                    justifyContent: "center",
                  }}
                >
                  <button type="submit" className="ConfirmButtonNormalMode">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OnboardingAppV2;
