import React, { useState } from "react";
import "./NoLogInPage.css";
import LogIn from "../Authentification/LogIn/LogIn";
import CreateUser from "../CreateUser/CreateUser";
import SetAkashaPass from "../Button/SetAkashaPass/SetAkashaPass";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default function NoLogInPage({ setNoLogInParcout }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    setError("");

    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      window.location.reload(); // recharger la page
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="NoLogIn">
      <div></div>
      <div className="contenerLogIn">
        <img
          src="/images/V2LogoAkasha.png"
          alt="AKASHA-SOCIETY"
          border="0"
          style={{
            maxWidth: "250px",
            maxHeight: "250px",
            marginBottom: "35px",
          }}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ borderRadius: "10px", border: "solid 1px #011D9B" }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ borderRadius: "10px", border: "solid 1px #011D9B" }}
        />
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <div className="hoverEffect" onClick={() => handleSubmit()}>
          Log in
        </div>

        <div
          style={{
            color: "#011D9B",
            textDecoration: "underline",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50px", // Hauteur fixe
            textAlign: "center",
            marginTop: "15px",
            cursor: "pointer",
          }}
          onClick={() => setNoLogInParcout(2)}
        >
          Forgot password?
        </div>

        <div
          className="ButtonFirstConnection"
          onClick={() => setNoLogInParcout(1)}
        >
          First connection
        </div>
        <div className="ApplyForMembershipcontener">
          <div
            className="ApplyForMembershipButton"
            onClick={() =>
              window.open(
                "https://44nqyr83x8g.typeform.com/to/aEgk0AD6?typeform-source=www.akashasociety.com",
                "_blank"
              )
            }
            style={{ cursor: "pointer" }} // Ajoute le curseur de la souris pour indiquer que c'est cliquable
          >
            Apply for membership
          </div>
        </div>

        {/*<LogIn />
        <CreateUser />*/}

        {/*<SetAkashaPass />*/}
      </div>
      <div></div>
    </div>
  );
}
//https://i.ibb.co/jrDWXP4/AKASHA-SOCIETY-Logo-Brand-HD-profil-insta.png
