import React, { useState, useEffect } from "react";
import "./MyBooking.css";
import TableMyBooking from "./TableMyBooking/TableMyBooking";

const MyBooking = ({ UserID }) => {
  return (
    <>
      <TableMyBooking UserID={UserID} />
    </>
  );
};

export default MyBooking;
