import React, { useState } from "react";
import "./AddNewAkashaPass.css";
import { AddNewAkashaPAss } from "../../../Logic/HandleClickFunctions";

const AddNewAkashaPass = () => {
  const [formData, setFormData] = useState({
    PassName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle form submission, such as calling an API to save the Akasha pass details
    console.log(formData);
    AddNewAkashaPAss(formData);
    setFormData({
      PassName: "",
    });
  };

  return (
    <div className="AddNewAkashaPassContainer">
      <h2>Add New Akasha Pass</h2>
      <form onSubmit={handleSubmit} className="AddNewAkashaPassForm">
        <div className="form-group">
          <label htmlFor="PassName">Pass Name</label>
          <input
            type="text"
            id="PassName"
            name="PassName"
            value={formData.PassName}
            onChange={handleChange}
            placeholder="Enter pass name"
            required
          />
        </div>

        <button type="submit" className="SubmitButton">
          Add Akasha Pass
        </button>
      </form>
    </div>
  );
};

export default AddNewAkashaPass;
