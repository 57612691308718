import "./App.css";
import React, { useState, useEffect } from "react";
import MainDashNSFW from "./components/MainDash/MainDashNSFW/MainDashNSFW";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebaseConfig";
import Registration from "./components/Authentification/Authentification";
import NoLogInPage from "./components/NoLogInPage/NoLogInPage";
import Header from "./components/Header/Header";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import MenuQuestion2options from "./components/MenuQuestion2options/MenuQuestion2options";
import BookTableSanctuary from "./components/BookTableSanctuary/BookTableSanctuary";
import { getUserData } from "./Logic/HandleClickFunctions";
import DisplaySanctuaryBookingAdmin from "./components/DisplaySanctuaryBookingAdmin/DisplaySanctuaryBookingAdmin";
import BookTableSanctuaryV2 from "./components/BookTableSanctuaryV2/BookTableSanctuaryV2";
import BookAClass from "./components/BookAClass/BookAClass";
import BookASocialEvent from "./components/BookASocialEvent/BookASocialEvent";
import FirstConnectionProcess from "./components/FirstConnectionProcess/FirstConnectionProcess";
import ForgotPassWord from "./components/ForgotPassWord/ForgotPassWord";
import BackOffice from "./components/BackOffice/BackOffice";
import HeaderBackOffice from "./components/HeaderBackOffice/HeaderBackOffice";
import OnboardingAppV2 from "./components/OnboardingAppV2/OnboardingAppV2";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "./firebaseConfig";

function App() {
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [menuChoice, setmenuChoice] = useState(0);
  const [NoLogInParcout, setNoLogInParcout] = useState(0);

  const [usersDB] = useCollection(collection(getFirestore(app), "users"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchData() {
      if (user) {
        const fetchedUserData = await getUserData(user.uid);
        setUserData(fetchedUserData);
      }
    }
    fetchData();
  }, [user, usersDB]);

  return (
    <div className="App">
      {user ? (
        <div className="AppGlass">
          {/* Rendu de l'en-tête en fonction du rôle de l'utilisateur */}
          {userData && userData.Role === "Owner" ? (
            <HeaderBackOffice
              menuChoice={menuChoice}
              setmenuChoice={setmenuChoice}
            />
          ) : (
            <Header menuChoice={menuChoice} setmenuChoice={setmenuChoice} />
          )}

          {/* Rendu conditionnel basé sur les données de l'utilisateur */}
          {userData && userData.Role === "Owner" ? (
            <BackOffice />
          ) : userData && userData.firstConnectionV2app === false ? (
            <OnboardingAppV2 userId={user.uid} />
          ) : (
            <>
              {/* Rendu des différentes options de menu */}
              {menuChoice === 0 && (
                <MenuQuestion2options
                  menuChoice={menuChoice}
                  setmenuChoice={setmenuChoice}
                />
              )}

              {menuChoice === 5 && <BookTableSanctuary />}
              {menuChoice === 1 && (
                <BookTableSanctuaryV2
                  setmenuChoice={setmenuChoice}
                  UserID={user.uid}
                />
              )}
              {menuChoice === 2 && <BookAClass UserID={user.uid} />}
              {menuChoice === 3 && <BookASocialEvent UserID={user.uid} />}
              {menuChoice === 12 && <MainDashNSFW />}
              {menuChoice === 4 && <ProfilePage UserID={user.uid} />}
              {menuChoice === 40 && <DisplaySanctuaryBookingAdmin />}
            </>
          )}
        </div>
      ) : (
        <>
          {/* Rendu pour les utilisateurs non connectés */}
          {NoLogInParcout === 0 && (
            <NoLogInPage setNoLogInParcout={setNoLogInParcout} />
          )}
          {NoLogInParcout === 1 && (
            <FirstConnectionProcess setNoLogInParcout={setNoLogInParcout} />
          )}
          {NoLogInParcout === 2 && (
            <ForgotPassWord setNoLogInParcout={setNoLogInParcout} />
          )}
        </>
      )}
    </div>
  );
}

export default App;
