import React, { useState } from "react";
import "./CreateNewClass.css";
import { HandleCreateNewClass } from "../../../Logic/HandleClickFunctions";

const CreateNewClass = () => {
  const [formData, setFormData] = useState({
    Type: "",
    title: "",
    Day: "",
    Hour: "",
    Duration: "",
    FacilitatorName: "",
    Description: "",
  });

  // Fonction de gestion de changement de chaque champ
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    HandleCreateNewClass(formData);
    console.log("Form Data:", formData); // Affiche les données dans la console pour tester
    // Ici, vous pourriez envoyer `formData` à votre base de données ou API
  };

  return (
    <div className="ContenerCreateNewClass">
      <div
        style={{
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        Details new class
      </div>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
        }}
      >
        <input
          type="text"
          name="Type"
          placeholder="Event Type"
          value={formData.Type}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="title"
          placeholder="Event Name"
          value={formData.title}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="Day"
          placeholder="Date"
          value={formData.Day}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="Hour"
          placeholder="Hour (MORNING/)"
          value={formData.Hour}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="Duration"
          placeholder="Duration"
          value={formData.Duration}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="FacilitatorName"
          placeholder="Facilitator"
          value={formData.FacilitatorName}
          onChange={handleInputChange}
          required
        />

        <textarea
          name="Description"
          placeholder="Description"
          value={formData.Description}
          onChange={handleInputChange}
          required
          rows="4" // Spécifie la hauteur en lignes
          style={{
            resize: "vertical", // Permet aux utilisateurs d'ajuster la hauteur, mais pas la largeur
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />

        <button
          type="submit"
          style={{
            padding: "10px",
            fontWeight: "bold",
            backgroundColor: "#e35508",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateNewClass;
