import React, { useState, useEffect } from "react";
import "./DisplayAndValidateParticipantWellnessEvent.css";
import {
  RejectBookingWellnessEvent,
  ApprovedBookingWellnessEvent,
  GetSelectedWellnessEventForValidationParticipant,
} from "../../../../../Logic/HandleClickFunctions";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../../firebaseConfig";

const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Rejected") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

const DisplayAndValidateParticipantWellnessEvent = ({ SelectedEventID }) => {
  const [SelectedEvent, setSelectedEvent] = useState(null);
  const [Akasha] = useCollection(collection(getFirestore(app), "Akasha"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      setSelectedEvent((event) => null);
      let Data = await GetSelectedWellnessEventForValidationParticipant(
        SelectedEventID
      );
      setSelectedEvent(Data);
    }
    fetchOrders();
  }, [Akasha, SelectedEventID]);

  return (
    <div className="ContenerDisplayAndValidateParticipant">
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Prenom</TableCell>
              <TableCell align="left">Nom</TableCell>
              <TableCell align="left">Event name</TableCell>
              <TableCell align="left">statut</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {SelectedEvent != null &&
              SelectedEvent.ListeParticipant.map((row) =>
                row.hasOwnProperty("userData") ? (
                  <TableRow
                    key={row.uid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.userData.FirstName}
                    </TableCell>
                    <TableCell align="left">{row.userData.LastName}</TableCell>
                    <TableCell align="left">{SelectedEvent.title}</TableCell>
                    <TableCell align="left" className="Details">
                      <span className="status" style={makeStyle(row.Status)}>
                        {row.Status}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            padding: "15px",
                            borderRadius: "5px",
                            backgroundColor: "green",
                            cursor: "pointer",
                            marginRight: "5px",
                          }}
                          onClick={() =>
                            ApprovedBookingWellnessEvent(
                              SelectedEvent.ID,
                              row.uid
                            )
                          }
                        >
                          Approved
                        </div>
                        <div
                          style={{
                            padding: "15px",
                            borderRadius: "5px",
                            backgroundColor: "red",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            RejectBookingWellnessEvent(
                              SelectedEvent.ID,
                              row.uid
                            )
                          }
                        >
                          Rejected
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{row.userData.PhoneNumber}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    key={row.uid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell align="left" className="Details">
                      <span className="status" style={makeStyle(row.Status)}>
                        {row.Status}
                      </span>
                    </TableCell>

                    <TableCell align="left">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            padding: "15px",
                            borderRadius: "5px",
                            backgroundColor: "green",
                            cursor: "pointer",
                            marginRight: "5px",
                          }}
                          onClick={() =>
                            ApprovedBookingWellnessEvent(
                              SelectedEvent.ID,
                              row.uid
                            )
                          }
                        >
                          Approved
                        </div>
                        <div
                          style={{
                            padding: "15px",
                            borderRadius: "5px",
                            backgroundColor: "red",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            RejectBookingWellnessEvent(
                              SelectedEvent.ID,
                              row.uid
                            )
                          }
                        >
                          Rejected
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DisplayAndValidateParticipantWellnessEvent;
