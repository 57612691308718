import React, { useState, useEffect } from "react";
import MenuVerticaleBackOffice from "./MenuVerticaleBackOffice/MenuVerticaleBackOffice";
import "./BackOffice.css";
import MainDashBoradEvent from "./MainDashBoradEvent/MainDashBoradEvent";
import AddNewAkashaPass from "./AddNewAkashaPass/AddNewAkashaPass";

const BackOffice = () => {
  const [MenuSelection, setMenuSelection] = useState(0);
  return (
    <div className="ContenerBackOffice">
      <MenuVerticaleBackOffice
        MenuSelection={MenuSelection}
        setMenuSelection={setMenuSelection}
      />
      {MenuSelection === 0 && <div>MainDashBoradBooking</div>}
      {MenuSelection === 1 && <MainDashBoradEvent />}
      {MenuSelection === 2 && <div>MainDashBoradMembers</div>}
      {MenuSelection === 3 && <AddNewAkashaPass />}
    </div>
  );
};

export default BackOffice;
