import React, { useState, useEffect } from "react";
import "./ManageWelnessEventBooking.css";
import { DataAnalyseUseOfThePlatform } from "../../../../Logic/HandleClickFunctions";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

import DisplayWellnessEventForFackOffice from "./DisplayWellnessEventForFackOffice/DisplayWellnessEventForFackOffice";
import DisplayAndValidateParticipantWellnessEvent from "./DisplayAndValidateParticipantWellnessEvent/DisplayAndValidateParticipantWellnessEvent";

const ManageWelnessEventBooking = () => {
  const [SelectedEvent, SetSelectedEvent] = useState(null);
  const [SelectedParticipant, SetSelectedParticipant] = useState(null);

  const [Data, setData] = useState(null);

  const [Akasha] = useCollection(collection(getFirestore(app), "Akasha"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      let Data = await DataAnalyseUseOfThePlatform();
      setData(Data);
    }
    fetchOrders();
  }, [Akasha]);

  console.log(Data);

  return (
    <div className="ContenerManageWellnessEventBooking">
      {Data && (
        <>
          <DisplayWellnessEventForFackOffice
            SetSelectedEvent={SetSelectedEvent}
            SelectedEvent={SelectedEvent}
            Data={Data}
          />
          {SelectedEvent && (
            <DisplayAndValidateParticipantWellnessEvent
              SelectedEventID={SelectedEvent.ID}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ManageWelnessEventBooking;
