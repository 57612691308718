import React, { useState, useEffect } from "react";
import "./BookASocialEvent.css";
import { GetListSocialEventForTheWeek } from "../../Logic/HandleClickFunctions";
import BookASocialEventComputerMode from "./BookASocialEventComputerMode/BookASocialEventComputerMode";
import BookASocialEventMobileMode from "./BookASocialEventMobileMode/BookASocialEventMobileMode";

// Composant pour le planning des ateliers
const BookASocialEvent = ({ UserID }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Fonction pour mettre à jour la largeur de la fenêtre
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Ajouter l'écouteur d'événements
    window.addEventListener("resize", handleResize);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth >= 1100 ? (
        <BookASocialEventComputerMode UserID={UserID} />
      ) : (
        <BookASocialEventMobileMode UserID={UserID} />
      )}
    </>
  );
};

export default BookASocialEvent;
