import React, { useState, useEffect } from "react";
import "./ModalBookSocialEvent.css";
import ModalBookSocialEventMobileMode from "./ModalBookSocialEventMobileMode/ModalBookSocialEventMobileMode";
import ModalBookSocialEventComputerMode from "./ModalBookSocialEventComputerMode/ModalBookSocialEventComputerMode";

const ModalBookSocialEvent = ({ CalssData, UserID }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Fonction pour mettre à jour la largeur de la fenêtre
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Ajouter l'écouteur d'événements
    window.addEventListener("resize", handleResize);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {windowWidth >= 1100 ? (
        <ModalBookSocialEventComputerMode
          SocialEventData={CalssData}
          UserID={UserID}
        />
      ) : (
        <ModalBookSocialEventMobileMode CalssData={CalssData} UserID={UserID} />
      )}
    </>
  );
};

export default ModalBookSocialEvent;
