import React, { useState, useEffect } from "react";
import "./ProfilePage.css";
import UserDetail from "./UserDetail/UserDetail";
import MembershipDetail from "./MembershipDetail/MembershipDetail";
import MyBooking from "./MyBooking/MyBooking";
import Security from "./Security/Security";
const ProfilePage = ({ UserID }) => {
  const [Menu, setMenu] = useState(0);
  return (
    <>
      <div></div>
      <div className="ProfilContener">
        <div className="HearderProfilPage">
          <div className="TextHearderProfilPage">My Profile</div>
          <div className="MenuHearderProfilPage">
            <div
              className={Menu === 0 ? "MenuItemSelected" : "MenuItem"}
              onClick={() => setMenu(0)}
            >
              Details
            </div>
            <div
              className={Menu === 1 ? "MenuItemSelected" : "MenuItem"}
              onClick={() => setMenu(1)}
            >
              My Membership
            </div>
            <div
              className={Menu === 2 ? "MenuItemSelected" : "MenuItem"}
              onClick={() => setMenu(2)}
            >
              My Bookings
            </div>
            <div
              className={Menu === 3 ? "MenuItemSelected" : "MenuItem"}
              onClick={() => setMenu(3)}
            >
              Security
            </div>
          </div>
        </div>
        {Menu === 0 && <UserDetail />}
        {Menu === 1 && <div> Not yet available{/*<MembershipDetail />*/}</div>}
        {Menu === 2 && <MyBooking UserID={UserID} />}
        {Menu === 3 && <Security />}
      </div>
      <div></div>
    </>
  );
};

export default ProfilePage;
