import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { HandleCancelBooking } from "../../../../../Logic/HandleClickFunctions";
import { FaTrashAlt } from "react-icons/fa";
import "./ModalCancelBooking.css";

const ModalCancelBooking = ({ CalssData, UserID }) => {
  console.log(CalssData);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div onClick={handleOpen} style={{ cursor: "pointer" }}>
        <FaTrashAlt />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "#FBF7EE",
            boxShadow: 24,
            p: 0, // Enlever padding de Box
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              padding: "25px",
              backgroundColor: "#FBF7EE",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              display: "flex",
              flexDirection: "column",
              color: "#011D9B",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              CONFIRM BOOKING CANCELATION
            </div>
            <div
              style={{
                fontSize: "16px",
                textAlign: "center",
                marginTop: "35px",
              }}
            >
              Do you really event to cancel your booking ?{" "}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginRight: "20px",
            }}
          >
            <div></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "15px",
                marginBottom: "20px",
              }}
            >
              <div
                onClick={() => {
                  HandleCancelBooking(CalssData);
                  handleClose();
                }}
                className="ButtonCancelBooking"
              >
                Yes
              </div>
              <div
                onClick={() => {
                  handleClose();
                }}
                className="ButtonCancelBooking"
              >
                No
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalCancelBooking;
