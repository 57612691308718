import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { TextField, Button, Dialog, DialogActions } from "@mui/material";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";

import { CreateNewSanctuaryBooking } from "../../Logic/HandleClickFunctions";

function BookTableSanctuary() {
  const [selectedDate, setSelectedDate] = useState({ $d: new Date() });
  const [selectedTime, setSelectedTime] = useState(dayjs());
  const [timeConfirmed, setTimeConfirmed] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const [open, setOpen] = useState(false);

  const handleOk = () => {
    console.log("Confirmed time:", selectedTime);
    setOpen(false);
    CreateNewSanctuaryBooking(user.uid, selectedDate, selectedTime);
  };

  const handleCancel = () => {
    console.log("Cancelled");
    setOpen(false);
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    setOpen(true);
  };

  const handleTimeChange = (newTime) => {
    console.log("TimeCahnge");
    setSelectedTime(newTime);
  };

  return (
    <>
      <div></div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "100%", height: "100%" }}>
          {" "}
          {/* Assurez-vous que cette div parente prend toute la taille nécessaire */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              onChange={handleDateChange}
              sx={{
                width: "100%", // Fait en sorte que le calendrier prenne toute la largeur de la div parente
                height: "100% !important", // Fait en sorte que le calendrier prenne toute la hauteur de la div parente
                "& .MuiCalendarPicker-root": {
                  width: "100%",
                  height: "100% !important",
                  backgroundColor: "blue",
                },
                "& .MuiPickersDay-root": {
                  width: "100%", // Ajuste la taille de chaque jour pour s'adapter
                  height: "100% !important",
                },
              }}
            />
          </LocalizationProvider>
        </div>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>
              {selectedDate.$d.toLocaleString("fr-FR", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}{" "}
              A {selectedTime.$d.getHours()} H {selectedTime.$d.getMinutes()}
            </DialogTitle>

            <TimeClock
              displayStaticWrapperAs="mobile"
              value={selectedTime}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
              ampm={false}
            />
            <DialogActions>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleOk} color="primary">
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>
        </LocalizationProvider>
      </div>

      <div> </div>
    </>
  );
}

export default BookTableSanctuary;
