import React, { useState, useEffect } from "react";
import "./MenuVerticaleBackOffice.css";

const MenuVerticaleBackOffice = ({ MenuSelection, setMenuSelection }) => {
  return (
    <div className="ContenerMenuVerticalBackOffice">
      <div className="ContenerLinkMenuVerticalBackOffice">
        <div
          className="CellLinkMenuVerticalBackOffice"
          onClick={() => setMenuSelection(0)}
        >
          Bookings
        </div>
        <div
          className="CellLinkMenuVerticalBackOffice"
          onClick={() => setMenuSelection(1)}
        >
          Events
        </div>
        <div
          className="CellLinkMenuVerticalBackOffice"
          onClick={() => setMenuSelection(2)}
        >
          Members
        </div>
        <div
          className="CellLinkMenuVerticalBackOffice"
          onClick={() => setMenuSelection(3)}
        >
          Akasha pass
        </div>
      </div>
    </div>
  );
};

export default MenuVerticaleBackOffice;
