// AppointmentScheduler.jsx
import React, { useEffect, useState } from "react";
import BookTableSanctuaryComputerMode from "./BookTableSanctuaryComputerMode/BookTableSanctuaryComputerMode";
import BookTableSanctuaryMobileMode from "./BookTableSanctuaryMobileMode/BookTableSanctuaryMobileMode";
import { DataAnalyseUseOfThePlatform } from "../../Logic/HandleClickFunctions";

const BookTableSanctuaryV2 = ({ setmenuChoice, UserID }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    DataAnalyseUseOfThePlatform();
    // Fonction pour mettre à jour la largeur de la fenêtre
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Ajouter l'écouteur d'événements
    window.addEventListener("resize", handleResize);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {windowWidth >= 1100 ? (
        <BookTableSanctuaryComputerMode
          setmenuChoice={setmenuChoice}
          UserID={UserID}
        />
      ) : (
        <BookTableSanctuaryMobileMode
          setmenuChoice={setmenuChoice}
          UserID={UserID}
        />
      )}
    </>
  );
};

export default BookTableSanctuaryV2;
