import React, { useState, useEffect } from "react";
import "./MainDashBoradEvent.css";
import CreateNewClass from "../CreateNewClass/CreateNewClass";
import CreateNewSocialEvent from "../CreateNewSocialEvent/CreateNewSocialEvent";
import ManageEventAndsanctuaryBooking from "./ManageEventAndsanctuaryBooking/ManageEventAndsanctuaryBooking";
import DisplayAndValidateParticipant from "./ManageEventAndsanctuaryBooking/DisplayAndValidateParticipant/DisplayAndValidateParticipant";
import ManageSocialEventBooking from "./ManageSocialEventBooking/ManageSocialEventBooking";
import ManageWelnessEventBooking from "./ManageWelnessEventBooking/ManageWelnessEventBooking";

const MainDashBoradEvent = () => {
  const [MenuClassSocial, setMenuClassSocial] = useState(0);
  const [SelectedEvent, SetSelectedEvent] = useState(null);
  const [SelectedParticipant, SetSelectedParticipant] = useState(null);

  return (
    <div className="ContenerMainDashBoradEvent">
      <div
        style={{ fontWeight: "bold", fontSize: "25px", marginBottom: "25px" }}
      >
        Events
      </div>
      <div className="SelectAreaMainDashBoradEvent">
        <div className="MenuClassSocialEvent">
          <div
            className="CellMenuClassSocialEvent"
            onClick={() => setMenuClassSocial(0)}
          >
            ALL
          </div>
          <div
            className="CellMenuClassSocialEvent"
            onClick={() => setMenuClassSocial(1)}
          >
            Class
          </div>
          <div
            className="CellMenuClassSocialEvent"
            onClick={() => setMenuClassSocial(2)}
          >
            Social Events
          </div>
        </div>
        <div style={{ marginLeft: "150px" }}>Selct Time </div>
      </div>
      <div className="MainDashBoradEvent">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {(MenuClassSocial === 0 || MenuClassSocial === 1) && (
            <ManageWelnessEventBooking />
          )}
          {MenuClassSocial === 2 && <ManageSocialEventBooking />}
          {/*<ManageEventAndsanctuaryBooking
            SetSelectedEvent={SetSelectedEvent}
            SelectedEvent={SelectedEvent}
          />
          {SelectedEvent && (
            <DisplayAndValidateParticipant
              SelectedEvent={SelectedEvent}
              SelectedParticipant={SelectedParticipant}
              SetSelectedParticipant={SetSelectedParticipant}
            />
          )}*/}
        </div>
        <div>
          {(MenuClassSocial === 0 || MenuClassSocial === 1) && (
            <CreateNewClass />
          )}
          {MenuClassSocial === 2 && <CreateNewSocialEvent />}
        </div>
      </div>
    </div>
  );
};

export default MainDashBoradEvent;
