import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableMyBooking.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../firebaseConfig";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

import { GetUserBookingData } from "../../../../Logic/HandleClickFunctions";
import ModalCancelBooking from "./ModalCancelBooking/ModalCancelBooking";

const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Rejected") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

export default function TableMyBooking({ UserID }) {
  const [user, loading, error] = useAuthState(auth);
  const [Data, setData] = useState([]);

  const [users] = useCollection(collection(getFirestore(app), "users"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [Akasha] = useCollection(collection(getFirestore(app), "Akasha"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      let Data = await GetUserBookingData(UserID);
      setData(Data);
    }
    fetchOrders();
  }, [users, Akasha]);

  return (
    <>
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="left">Event Name</TableCell>
                <TableCell align="left">Pax</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Time</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {Data != null &&
                Data.map((row) => (
                  <TableRow
                    key={row.type}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.type}
                    </TableCell>
                    <TableCell align="left">{row.eventName}</TableCell>
                    <TableCell align="left">{row.Pax}</TableCell>
                    <TableCell align="left">{row.Date}</TableCell>
                    <TableCell align="left">{row.Time}</TableCell>
                    <TableCell align="left">
                      <span className="status" style={makeStyle(row.Status)}>
                        {row.Status}
                      </span>
                    </TableCell>
                    <TableCell align="left" className="Details">
                      <ModalCancelBooking CalssData={row} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
